<template>
 <a-modal
  title="提示"
  :visible="isShow"
  ok-text="确认" 
  cancel-text="取消"
  @ok="handleSubmit"
  @cancel="handleCancel" 
 >
 <div class="tip">您有未送单的菜品需要送单，现在送单吗？</div>
 </a-modal>
</template>
<script>

export default {
  props:['data','isShow'],
  components: {
  },
  data() {
    return {     
    };
  },
  watch:{
    isShow:{
      handler(){
        if(this.isShow){
          const _this=this;
          document.onkeydown = function(e) {
            let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
            if (e1 && e1.keyCode == 13) { //点击F6先送
              _this.handleSubmit()
            } 
          }  
        }  
      },
      deep:true,
    }
  },
  created(){
   
    console.log('1111')
  },
  
   mounted(){ 
  },
  updated(){
  },
  methods: {
    handleSubmit(){   //点击确定按钮 
        this.$parent.$parent.$parent.$parent.$parent.clickSend(1)
        this.$emit('cancel',false)//关闭按钮
        this.$emit('turnOn',true)//传递开启结算modal
    },
    handleCancel(){ //点击取消按钮
    this.$emit('cancel',false)
    }
  }, 
};
</script>
<style lang="less" scoped>
/deep/.ant-modal{
  width:35vw !important;  
}
/deep/.ant-modal-content,/deep/.ant-modal-header{
   background-color: rgba(237, 238, 242, 1);
}
/deep/.ant-modal-body{
  padding:1vh 1vw;
}
/deep/.ant-modal-footer{
  text-align: center;
}
/deep/.ant-btn-primary{
   border-color: rgba(255, 102, 0, 1);
   background-color: rgba(255, 102, 0, 1);
}
/deep/.ant-btn {
  width: 8vw;
  height: 4.6vh;
  font-size: 1.8vh;
  margin-right: 2vw;
  margin-left: 1vw;
}
/deep/.ant-modal-footer button + button {
  width: 8vw;
  height: 4.6vh;
  font-size: 1.8vh;
  border-radius: 0.5vh;
  color: white;
  margin-left: 5vh;
  background: rgb(238, 121, 12);
  border-color: rgb(238, 121, 12);
}
/deep/.ant-modal-title{
  font-size: 1.8vh;
}
/deep/.ant-form-item-required::before{
  color:rgba(255, 102, 0, 1);
}
.chooseModal{
  .title{
    font-size: 1.8vh;
    line-height: 5vh;
  }
}
.tip{
  font-size: 1.8vh;
}
  
</style>
