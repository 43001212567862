<template>
 <a-modal
  title="取消赠送"
  :visible="isShow"
  ok-text="确认" 
  cancel-text="取消"
  @ok="handleSubmit"
  @cancel="handleCancel" 
 >
  <div class="chooseModal">
   确定要取消对菜品[
   {{data && data.goods_name}}]
   的操作吗？
  </div>
 </a-modal>
</template>
<script>

export default {
  props:['data','isShow'],
  components: {
  },
  methods: {   
    handleSubmit(){   //点击确定按钮 
      const _this=this;   
          this.$emit('cancel',false)//关闭按钮
          this.$emit('chooseData',true);//向父组件传递选取的数据
    },
    handleCancel(){ //点击取消按钮
    this.$emit('cancel',false)   
    }
  }, 
};
</script>
<style lang="less" scoped>
/deep/.ant-modal{
  width:40vw !important;  
}
/deep/.ant-modal-content,/deep/.ant-modal-header{
   background-color: rgba(237, 238, 242, 1);
}
/deep/.ant-modal-body{
  padding:1vh 1vw;
}
/deep/.ant-modal-footer{
  text-align: center;
}
/deep/.ant-btn-primary{
   border-color: rgba(255, 102, 0, 1);
   background-color: rgba(255, 102, 0, 1);
}
/deep/.ant-modal-footer button + button{
  margin-left:2vw ;
}
/deep/.ant-modal-title{
  font-size: 1.8vh;
}
/deep/.ant-form-item-required::before{
  color:rgba(255, 102, 0, 1);
}
/deep/.ant-form-item{
  display: flex;
}
/deep/.ant-form-item-control {
  width:12vw;
}
/deep/.ant-form-item label {
  font-size:1.8vh;
}
.chooseModal{
  .title{
    font-size: 1.8vh;
    line-height: 5vh;
  }
}
  
</style>
