<template>
  <a-modal
    :title="`结算--${this.$route.query.table_name}(${data.people})`"
    :visible="isShow"
    ok-text="确认"
    cancel-text="取消"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
  <div @click="infocusInput">
     <div class="content">
      <div class="tableLeft">
        <div class="top">
          <a-table
            :columns="columns" 
            :data-source="datas"
            bordered
            :rowKey="(record,index)=>{return index}"
            :pagination="{
            pageSize: 6
            }"
            :locale="{emptyText: '暂无数据'}"
            >
              <span slot="goods_name" slot-scope="text,record">
                {{text}}
                <span v-if="record.spec_content" class="textColor">  /{{record.spec_content}}/</span>
              </span>
              <span slot="total_num" slot-scope="text,record">
                {{text}}
                <span v-if="record.is_giving===2" class="biao">(赠)</span>
                <span v-if="record.is_foodback===2" class="biao">(退{{record.refund_total_num}})</span>
              </span>
            </a-table>
        </div>
        <div class="center"></div>
        <div class="bottom">
            <a-table 
            :columns="com"  
            :data-source="da"
            bordered
            :rowKey="(record,index)=>{return index}"
            :pagination="{
            pageSize: 4
            }"
            :locale="{emptyText: '暂无数据'}"
          >
              <span slot="tags" slot-scope="tags">
                  {{pay(tags)}}            
              </span>
          </a-table>
        </div>
      </div>
      <div class="tableRight">
        <ul class="amount">
          <li>原始金额:  &nbsp;￥{{total_price?total_price:0.00}} </li>
          <li>折扣金额:  &nbsp;￥{{discount_price?discount_price:0.00}}</li>
          <li>减免金额: &nbsp;￥{{free_price?free_price:0.00}}</li>
          <li>抹零金额: &nbsp;￥{{maling_price?maling_price:0.00}}</li>
        </ul>
        <ul 
          class="amount impor" 
          :class="[order_price === payed_price && this.da.length>0? 'finish' : '',]">
          <li class="yingshou">应收金额:  &nbsp;￥{{order_price?order_price:0.00}}</li>
          <li class="daishou">待收金额: &nbsp;<span v-if="userMes&&userMes.user_id==0">￥{{nopay_price?nopay_price:0.00}}</span><span style="color:rgba(212, 48, 48, 1)" v-if="userMes.user_id &&  userMes.user_id !==0"><my-icon slot="prefix" type="icon-VIP" style="color:rgba(212, 48, 48, 1);width:2vw;height:2vw;"></my-icon>{{nopay_price?nopay_price:0.00}}元</span></li>
          <li>已收金额: &nbsp;￥{{payed_price?payed_price:0.00}} </li>
          <li v-if="showNumber===1" class="saoma">扫码金额: &nbsp;
            <a-input  v-model="saoYing" class="saoinput" @change="inputSao" @click="focusInput()"  @mousedown="qvMo" ref="numInput" id="input" @blur="insertInputTxt" />
            <div class="code" v-if="shownum">
              <keyNum :parentNum="saoYing" @hidden_num="updateNum" :cursorIndex="index"/>
              <a-button @click="clickSure">确定</a-button>
            </div>
            </li>
        </ul>
         <div class="orderUser" v-if="userMes.user_id &&  userMes.user_id !==0">
           <div  class="closebutton" @click="closeVip">取消</div>
            <ul class="user">
              <li>会员姓名: &nbsp;{{userMes && userMes.nick_name}}</li>
              <li>手机号: &nbsp;{{userMes && userMes.mobile}}</li>
            </ul>
          </div>
        <div class="contents">
        <div class="changecontent" >
          <div>
          <div class="payway" v-if="showNumber===0" >
            <div>
               <div class="font"> 请选择支付方式</div>
              <img src="@/assets/choosepay.png" alt="" class="choosepay">
            </div>    
          </div>
          <div class="sao" v-if="showNumber===1">
            <div>
              <img src="@/assets/sao.png" alt="" class="choosepay">
              <div class="input" >
              <a-form-model 
                :model="form" 
                :rules="rules"
                ref="ruleForm"
              >
                <a-form-model-item ref="price" prop="price"  label="">
                  <a-input
                    class="input"
                    v-model="form.price"
                    ref="input" 
                  />
                </a-form-model-item>          
            </a-form-model> 
          </div>
            </div>  
          </div>
          <div>
          <a-result v-if="icon === 2" class="loading">
            <template #icon>
              <a-icon type="smile" theme="outlined" :style="{ color: 'rgba(40, 172, 60, 1)' }" />
              <div class="count">{{payWay}}: {{payPrice}}元</div>
              <div class="finishjie" @click="finishRequest" v-if="nopay_price==0.00 && da.length>0">完成结算</div>
            </template>
          </a-result>
          <a-result  v-if="icon === 3" class="loading">
            <template #icon >
              <a-icon type="frown" theme="outlined" :style="{ color: 'rgba(40, 172, 60, 1)' }" />
              <div class="count">支付失败!</div>
            </template>
          </a-result>  
          <div class="loading" v-if="icon ===1 ">
            <Loading />
          </div>
          </div>
          </div>
        </div>
        </div>
         <div class="ways">
           <div class="zhes" v-if="nopay_price==0.00  && da.length>0"></div>
            <ul class="pay">
              <li
              v-for="(item,index) in payment" 
              :key="index"
              :class="[activeIndex === index ? 'active' : '', 'everyButton']"
              @click="ClickButton(item ,index)"
             
              >
              {{item.type_desc && item.type_desc}}
              </li>
            </ul> 
         </div>
      </div> 
      <div class="tableFinally">
        <ul>
          <li @click="clickCash">现金</li>
          <!-- <li @click="click_wx_zfb('weixin')">个人微信</li> -->
          <!-- <li @click="click_wx_zfb('zhifubao')">个人支付宝</li> -->
          <li @click="hangButton" v-if="permission.indexOf('/clerk/order/hang') > -1">挂账</li>
          <li @click="discountButton" class="discount" v-if="permission.indexOf('/clerk/order/discount') > -1">
            折扣
            <div class="replace-item" v-if="discountNumber">
              <div class="jiao"></div>
              <div class="desc">{{discountNumber}}折</div>
            </div>
          </li>
          <li @click="freeButton" class="discount" v-if="permission.indexOf('/clerk/order/free') > -1">减免
             <div class="replace-item" v-if="free_price!=0.00">
              <div class="jiao"></div>
              <div class="desc">免</div>
            </div>
          </li>
        </ul>
      </div>   
    </div>
    <!-- <div class="bottom_b">
      <div class="other">
        <a-dropdown placement="topCenter">
          <a-menu slot="overlay" @click="handleMenuClick">
            <a-menu-item key="1"> <a-icon type="user" />会员查询</a-menu-item>
            <a-menu-divider />
            <a-menu-item key="2"> <a-icon type="money-collect" />会员充值 </a-menu-item>
            <a-menu-divider />
            <a-menu-item key="3"> <a-icon type="usergroup-add" />新增会员 </a-menu-item>
            <a-menu-divider />
            <a-menu-item key="4"> <a-icon type="notification" />沽清 </a-menu-item>
            <a-menu-divider />
            <a-menu-item key="5"> <a-icon type="snippets" />客单 </a-menu-item>
          </a-menu>
          <a-button style="margin-left: 8px"> 其他功能 <a-icon type="up" /> </a-button>
        </a-dropdown>
      </div>
      <div class="button">
        <div class="lock"><div class="time dian" @click="dianCai">继续点菜</div></div>
        <div class="lock"><div class="time jie" @click="finishRequest">完成结算</div></div>
      </div>    
    </div> -->
    <moneyModal  :show="showMoney" @cancel="changeShowMo"  :sheng="nopay_price" @chooseData="getMoneyNumber(arguments)"/>
    <weixinZhifubao  :show="showWXZFB" @cancel="showWXZFB = false" :sheng="nopay_price" :type="wx_zfb" @chooseData="getMoneyNumber(arguments)"/>
    
    <!-- <moneyCodeModal :isShow="showMoneyCode" :ying="order_price" :sheng="nopay_price" :type="type_name" :wPrice="moneyCurrent" :status="moneyPayStatu"  @cancel="changeMoneyChatCode"/> -->

    <!-- <tipModal :data="clickEveryGood" :wasShow="showTip" /> -->
    <WeChatModal  :isShow="showWeChat" :ying="order_price" :sheng="nopay_price" @chooseData="getNumber(arguments)"  @cancel="changeWeChat"/>
    <WeChatCodeModal :isShow="showWeChatCode" :ying="order_price" :sheng="nopay_price" :type="type_name" :wPrice="weCurrent"  @cancel="changeWeChatCode"  @chooseData="update"/>
    <!-- <AlipayModal  :isShow="showAlipay" :ying="order_price" :sheng="nopay_price"  @chooseData="getAlipayNumber(arguments)" @cancel="changeAlipay"/>
    <AlipayCodeModal  :isShow="showAlipayCode" :ying="order_price" :sheng="nopay_price" :type="type_name" :wPrice="alipayCurrent"  @cancel="changAlipayCode" @chooseData="update"/> -->
   <!-- <paymentModal :data="clickEveryGood" :sheShow="showPayment" /> -->
    <VIPcardmodal  :isShow="showVIPcard" :ying="order_price" :sheng="nopay_price" :user="userMes"  @chooseData="getVipNumber(arguments)" :type="type_name" :userId="user_id" @cancel="changeVIPcard"/>
    <VIPcardCodeModal :isShow="showVIPcardCode" :ying="order_price" :sheng="nopay_price" :wPrice="vipCurrent" :type="type_name" :status="vipPayStatu" :user="userMes" @cancel="changeVIPcardCode"/>
    <discountModal  :isShow="showdiscount"  @cancel="changeDiscount" @chooseData="getDiscountNumber(arguments)" ref="son"/>
    <preferentialModal  :isShow="showpreferential" @cancel="changePreferential" @chooseData="getFreeNumber(arguments)"/>
    <paymentModal :data="da" :isShow="showPayment" :sheng="nopay_price" @chooseData="getHangNumber(arguments)" @cancel="changeHang" :order_no="order_no"/> 
    <Addmis :isShow="showAdd" @changeModal="changeAddModal"/>
    <Recharge :isShow="showRecharge" @changeModal="changeRechargeModal" :functionJump="true"/>
    <Member :isShow="showMember" :ying="order_price" :sheng="nopay_price"  :type="type_name" @changePrice="changetotalPrice" @cancel="clooseMember" @chooseData="getpayStatus(arguments)" :user="userMes" @changeYv="updateDetail"/>
    </div>
    <!-- <audio ref="audio" :src="speech_path" autoplay="autoplay"></audio> -->
  </a-modal>
</template>
<script>
import Vue from 'vue'
import moneyModal from "./moneyModal.vue";
import moneyCodeModal from "./moneyCodeModal.vue";
import AlipayCodeModal from "./AlipayCodeModal.vue";
import AlipayModal from "./AlipayModal.vue";
import WeChatModal from "./WeChatModal.vue";
import tipModal from "./tipModal.vue";
import VIPcardmodal from "./VIPcardmodal.vue";
import paymentModal from "./paymentModal.vue";
import preferentialModal from "./preferentialModal.vue";
import discountModal from "./discountrateModal.vue";
import WeChatCodeModal from './WeChatCodeModal.vue';
import VIPcardCodeModal from './VIPcardCodeModal.vue';
import weixinZhifubao from './weixin_zhifubao.vue'
import {orderUser,orderDiscount,finish,orderpaystatus,orderpay,orderHang,orderFree} from '@/request/business.js';
import Addmis from "@/views/mis/modules/Addmis.vue";
import Recharge from "@/views/mis/modules/Recharge.vue";
import Cookies from "js-cookie";
import { mapState , mapActions } from "vuex";
import keyNum from "@/components/keycode/num";
import Loading from '@/components/payLoading/payLoading';
import Member from './member.vue';
import { Divider, Icon } from 'ant-design-vue';
import iconFront from '@/assets/iconfont/iconfontvip.js'
const myicon = Icon.createFromIconfontCN({
    scriptUrl: iconFront,
 })
Vue.component('my-icon', myicon)
export default {
  props: [
    "data",
    "isShow",
    "wasShow",
    "wereShow",
    "heShow",
    "sheShow",
    "iShow",
    "weShow",
    "itShow",
    "youShow",
  ],
  props: ["data", "isShow"],
  components: {
    moneyModal, // 现金支付输入金额modal
    moneyCodeModal,//现金支付结果
    tipModal,
    VIPcardmodal,//vip余额支付modal
    WeChatModal, //微信支付输入金额modal
    AlipayModal,//支付宝输入金额modal
    AlipayCodeModal,//支付宝扫码modal
    paymentModal,//挂账modal
    discountModal, //折扣比例modal
    preferentialModal,//优免金额设置modal
    WeChatCodeModal, //微信扫码支付modal
    VIPcardCodeModal,//vip支付结果modal
    Addmis,//新增会员modal
    Recharge,//会员充值页面
    keyNum,
    Loading,//支付结果loading
    Member,//查询会员页面
    weixinZhifubao, //个人微信 支付宝
  },
  data() {
    return {
      showWXZFB:false,
      wx_zfb:'weixin',
      showMoney: false, //现金输入金额modal
      showMoneyCode:false,//现金支付结果
      showTip: false,
      showVIPcard: false, //vip余额支付输入余额modal
      showAlipay: false, //支付宝输入余额modal
      showAlipayCode: false, //支付宝扫码modal
      showPayment: false, //挂账modal
      showWeChat: false,//微信支付输入余额modal
      showWeChatCode:false,//微信扫码modal
      showdiscount: false, //选择折扣modal
      showpreferential: false,//优免金额设置modal
      showdiscountrate: false,//折扣比例modal
      showVIPcardCode:false,//vip支付结果modal
      showRecharge: false, //充值页面
       columns:[
        {
          title: '顺序',
          dataIndex: 'number',
          key: 'number',
        },
        {
          title: '菜品名称',
          dataIndex: 'goods_name',
          scopedSlots: { customRender: 'goods_name' },
        },
        {
          title: '数量',
          dataIndex: 'total_num',
          key: 'total_num',
          scopedSlots: { customRender: 'total_num' },
        },
        {
          title: '单位',
          dataIndex: 'goods_unit',
          key: 'goods_unit',
        },
        {
          title: '单价',
          dataIndex: 'goods_price',
          key: 'goods_price',
        },
       {
        title: '实收',
        dataIndex: 'total_pay_price',
        key: 'total_pay',
      },
    ],
    datas:[],
     com:[  //支付table头部
        {
          title: '顺序',
          dataIndex: 'number',
          key: 'number',
        },
        {
          title: '支付方式',
          dataIndex: 'type_desc',
          key: 'type_desc',
        },
        {
          title: '金额',
          dataIndex: 'payed_price',
          key: 'payed_price',
        },
         {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          scopedSlots: { customRender: 'tags' },
        },
    ],
    da:[], //支付table头部
    payment:[
            {type_desc:'会员(F4)',type_name:'balance'},
            {type_desc:'扫码支付(F2)',type_name:'aggregatepay'},   
    ],
    activeIndex:0,//点击激活的索引
    clickEveryGood:'',
    nopay_price:0.00,//待收金额
    payed_price:0.00,//已收金额
    free_price:0.00,//优免金额
    maling_price:0.00,//抹零金额
    order_price:0.00,//应收金额
    total_price:0.00,//原始金额
    discount_price:0.00,//折扣金额
    type_name:'',//  支付方式
    weCurrent:'',//微信支付金额
    keywords:'',//会员卡号
    userMes:{},//会员信息
    userStatue:'',//会员状态
    payButton:true,//会员余额支付按钮状态（是否可用）
    user_id:0, //区分是否是会员
    vipCurrent:'',//vip支付金额
    vipPayStatu:'',//vip会员卡支付状态
    moneyCurrent:'',//现金支付金额
    alipayCurrent:'',//支付宝支付的金额
    moneyPayStatu:'',//现金支付状态
    discountNumber:undefined,//打折数字
    showDis:false, //点击折扣按钮状态
    showAdd:false,//点击开启增加会员
    order_no:'',//订单号
    speech_path:'',//音频地址
    timeout:'',//接受定时器
    pay_status:false ,//防止重复调用完成结算
    showNumber:0,//content中间内容切换   0-选择支付方式 1-点扫码,2-扫码支付请求状态成功展示支付结果 3-会员支付 4现金支付 5-挂账 6-减免 7-自定义折扣
    saoYing:'0.00',//扫码应收金额（input）
    form: {
          price:''
        },
    rules: {   //付款码
      price: [
        {required: true,  trigger: "change" ,message: "请输大于0的数字",},
      ] 
    }, 
    timeouts:'',//定时器
    shownum:false,//是否展示软件盘
    icon:2,//扫码支付状态
    speech_path:'',//语音播报链接
    times:'',//支付接口请求不成功再次请求接口定时器
    showMember:false,//会员modal
    icon:0,//支付状态
    payPrice:0,//支付的金额
    payWay:'',//支付方式
    forms: { //现金
          price:this.nopay_price,
        },
    rules: {
          price: [
            {required: false,  trigger: "change" ,message: "请输大于0的数字",},
          ] 
    },
    zeroCount:0.00, //找零金额
     formhang: { //挂账
        name: '',
        mobile:''
      },
      ruleshang:{//挂账
        name: [
          {  message: '请输入挂账人', trigger: 'change' },
        ],
        mobile: [
          { required: true, message: '请输入电话', trigger: 'change' },
        ],
      },
      formfree:{ //优免
        price:'',
      },                     
      formDiscount:{
         price:'',
      },
      paylog_id:'',//支付状态
      grade:'',//等级
      data_state:0,//是否继续请求支付状态接口
      index:0,//光标聚焦位置
    };
  },
  mounted() {
    this.showNumber=0;
  },
    computed: {
    ...mapState(["topTabList",'activeName' , 'permission']),
      // monitor () {
		  //     return this.$store.state.refresh
	    // }
  },
  watch: {
    data: {
      handler() { 
        this.datas=this.data.orderGoods? this.addNumber(this.data.orderGoods):[];
        const table_name=this.$route.query.table_name && this.$route.query.table_name;
        this.datas.map(item=>{
          item.table_name=table_name
        })
        this.da= this.addNumber(this.data.paylog) ? this.addNumber(this.data.paylog):[];
        this.free_price=this.data.free_price ?this.data.free_price :0.00; //优免金额
        this.maling_price= this.data.maling_price ? this.data.maling_price:0.00; //抹零金额
        this.order_price=this.data.order_price ? this.data.order_price:0.00; //  应收金额
        this.discount_price=this.data.discount_price ? this.data.discount_price:0.00; //  折扣金额
        this.total_price=this.data.total_price && this.data.total_price; //原始金额
        this.payed_price=this.data.payed_price ? this.data.payed_price:0.00; //已经成功支付金额
        this.nopay_price=this.data.nopay_price ? this.data.nopay_price:0.00;//待收金额
        if(this.data.orderUser==undefined || this.data.orderUser.length==0){
          this.userMes={
            user_id:0,
            grade:{
              name:"",
              equity:{discount:""}
              }}
        }else{
           this.userMes=this.data && this.data.orderUser //获取初始化会员信息
        }
        console.log(JSON.stringify(this.userMes))
        
        this.grade=this.userMes.grade&& this.userMes.grade.length>0?this.userMes.grade.equity.discount:"";
        this.discountNumber = this.data.grade_ratio? this.data.grade_ratio:undefined; //折扣数
        this.order_no=this.data.order_no && this.data.order_no//订单号
        this.forms.price=this.nopay_price;
         this.user_id=this.data.orderUser&& this.data.orderUser.user_id;
        this.payButton= (this.data.orderUser && this.data.orderUser.balance>0)?false:true; //改变余额支付是否可用按钮状态
        const local=localStorage.getItem('keywords') && localStorage.getItem('keywords');
        if(local){
           if(this.data.orderUser && (JSON.parse(local).id === this.data.orderUser.user_id)){
            this.keywords= JSON.parse(local).name && JSON.parse(local).name// 获取input 框会员卡号或者手机号初始化信息
            this.user_id=JSON.parse(local).id && JSON.parse(local).id;
          }
        }
      },
      deep: true,
    },
    da:{
      handler(){
        if((this.order_price == this.payed_price && this.da.length>0)|| (this.nopay_price == 0.00 && this.da.length == 1)){
           clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
              this.finishRequest()
          }, 1700)
         
        }
      },
      deep:true,
    },
    form:{
      handler(){
         clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          console.log(this.form.price.length)
          if(this.form.price !=='' && (this.form.price.length==18 || this.form.price.length==19)){
               this.payRequest("扫码支付成功")
          }else if(this.form.price !=='' && this.form.price.length>19){
            this.$message.warn('请勿重复扫码!',1.5);
            this.form.price =''
          }   
        }, 10)
      },
      deep:true,
    },
    data_state:{
      handler(){
         const _this=this;   
            if(_this.data_state === 1){
              this.times=setInterval(() => {
                  setTimeout( 
                    _this.orderStatusRequest(_this.paylog_id)
                    , 0)           
              }, 1000);
           }else{
              clearInterval(this.times);
           };
      },
      deep:true,
    },
    forms:{
      handler(){
        if(parseFloat(this.forms.price)>0){
          this.zeroCount=this.forms.price-this.nopay_price;
        }else if(this.forms.price==""){
          this.zeroCount=0.00
        }
      },
      deep:true,
    },
    isShow:{
      handler(){
        if(this.isShow){
         const _this=this;
          document.onkeydown = function(e) {
            let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
            if (e1 && e1.keyCode == 115) { //点击F2扫码支付
            _this.ClickButton( {type_desc:'会员(F4)',type_name:'balance'},0)
            }else if(e1 && e1.keyCode == 113){
              _this.ClickButton(  {type_desc:'扫码支付(F2)',type_name:'aggregatepay'},1) 
            }
          }  
        }
      },
      deep:true,
    },
    // monitor:{
    //   handler(){
    //     const _this=this;
    //     this.speech_path=this.$store.state.speech_path ? this.$store.state.speech_path:'' ;
    //     alert( this.speech_path)
    //      if(this.speech_path){
    //              this.$nextTick(() => {
    //               _this.$refs.audio.play()
    //         })
    //           }  
    //   },
    //   deep:true,
    // }
    saoYing(val){
      this.$set(this , 'saoYing' , this.getTofixed(val))
    }
  },
 
  methods: {
    ...mapActions(['updateTabListAction','activeNameAction']),
    handleMenuClick(e) { //其他功能点击
      const foundingLoId=this.$route.query.foundingLoId && this.$route.query.foundingLoId;
      if(e.key==='5'){
        this.socketApi.websocketsend({"key": "'kedanAction",value:{token: Cookies.get("Access-Token"),founding_log_id:foundingLoId} }); 
      }else if(e.key==='2' || e.key==='1'){
        this.showRecharge=true;
      }else if(e.key==='3'){
          this.showAdd=true;
      }else if(e.key==='4'){
          let i = this.topTabList.findIndex(item => item.name == 'stock')
        if(i == -1){
          let arr = JSON.parse(JSON.stringify(this.topTabList))
          arr.push({
            isDetele: true,
            name: "stock",
            path: "/stock/damaged",
            title: "库存管理"
          })
          this.updateTabListAction(arr)
        }
        this.activeNameAction('stock')
        this.$router.push('/stock/sellOut?key=2')
      }
    },
    update(){
      this.$emit('changePrice',true)  
    },
    pay(status){
      if(status == 2){
        return '支付成功'
      }else if(status == 1){
        return '支付中'
      }else if(status == 3){
        return '支付失败'
      }
    },
    vipPay(){   //点击余额支付
      this.showVIPcard=true;
      this.type_name='balance';
    },
    changeShowMo(){
      this.showMoney = false;
    },
    changeWeChat(){ //支付微信
      this.showWeChat=false;
    },
     changeWeChatCode(){   //微信扫码
      this.showWeChatCode=false;
    },
    changeVIPcardCode(){  //vip支付结果
      this.showVIPcardCode=false;
    },
    changeVIPcard(){ //vip支付
      this.showVIPcard=false;
    },
    changeDiscount(){  //关闭选择折扣modal
      this.showdiscount=false;
    },
    changePreferential(){ //关闭优免modal
      this.showpreferential=false;
    },
    changeHang(){ //关闭挂账按钮
      this.showPayment=false;
    },
     addNumber(arr){  //添加表格序号
      if(arr){
        let i =1
        arr.map(item=>{       
          item.number = i;
           i++;
        })   
          return arr
      }
    },
    handleSubmit() {  //点击确定按钮
      const _this = this;
      _this.form.validateFields((err, values) => {
        if (!err) {
           this.activeIndex=0;
           this.showNumber=0;
          this.$emit("cancel", false); //关闭按钮
        }
      });
    },
    handleCancel() { //点击取消按钮 
      this.showNumber=0;
      this.activeIndex=0;
      this.icon=0;
      this.$emit("cancel", false);
    },
    ClickButton(item,index){ //点击支付方式按钮
     this.icon=0;
    this.activeIndex=index;
    this.type_name=item.type_name && item.type_name;
    if(item.type_name && item.type_name==='cash'){
      // this.showMoney=true;
       this.showNumber=4;
    }else if(item.type_name && item.type_name==="aggregatepay"){//点击扫码
      // this.showWeChat=true;
      this.showNumber=1;
      this.saoYing=this.nopay_price;
      this.$nextTick(() => {
          this.$refs.input.focus();
           this.$refs.numInput.select();
         
      });
    }else if(item.type_name && item.type_name==="discount"){
      // this.showdiscount=true; 
    }else if(item.type_name && item.type_name==="alipay"){
      this.showAlipay=true;
    }else if(item.type_name && item.type_name==="free"){
      this.showpreferential=true;
    }else if(item.type_name && item.type_name==="hang"){
      this.showPayment=true;
    }else if(item.type_name && item.type_name==="balance"){
      
      this.showMember=true;
      this.icon=0;
      this.showNumber=0;
    }
    },
    getMoneyNumber(number){  //现金接受付款金额
      this.payPrice=number[0];//付款金额
      this.icon=number[1];//付款状态
      this.showNumber=2;
      console.log( this.showNumber)
      if(number[1]==2){
        this.$emit('changePrice',true)
        this.payWay="成功收款";
      }
    },
    getNumber(number){  //微信接受付款金额
      this.weCurrent=number[0];
      if(number[2]){
        this.showWeChat=false;
        this.showWeChatCode=true;
        this.$emit('changePrice',true)      
      }
    },
     getAlipayNumber(number){  //支付宝付款金额
      this.alipayCurrent=number[0];
      if(number[2]){
        this.showAlipay=false;
        this.showAlipayCode=true;
        this.$emit('changePrice',true)      
      }
    },
     getVipNumber(number){  //vip余额接受付款金额
      this.vipCurrent=number[0]
      this.vipPayStatu=number[1]
      if(number[2]){
        this.showVIPcard=false;
        this.showVIPcardCode=true;
        // this.searchUser(1)
        this.$emit('changePrice',true)     
      }
    },
    getDiscountNumber(number){ //折扣重新请求订单详情
      console.log(number[0])
      if(number[0]){  
         this.discountRequest(1,number[0]);
      }
    },
    getFreeNumber(number){ //优免重新请求订单详情
      if(number[0]){
        this.icon=2;
        this.payWay="减免成功"
        this.payPrice=number[1]
        this.showPayment=false;
        this.showNumber=2;
        this.$emit('changePrice',true)  
      }   
    },
    getHangNumber(number){  //挂账重新请求订单
      if(number[0]){
        this.showPayment=false;
        this.payPrice=this.nopay_price;
        this.icon=2;
        this.payWay="挂账成功";
        this.showNumber=2;
        this.$emit('changePrice',true)  
      } 
    },
  searchUser(number){   //查询会员 
    const _this=this;
     if(number===2){
       localStorage.removeItem('keywords');
       this.keywords=""
     }   
     const message= {
       founding_log_id:this.$route.query.foundingLoId && this.$route.query.foundingLoId,
       keywords:this.keywords,
       select_type:number
     }   
     orderUser(message)
        .then((res) => {
          if(res.data.status===200){
              _this.userStatue=number;
              _this.userMes=res.data.data;
              _this.user_id=res.data.data.user_id && res.data.data.user_id;
              if(res.data.data.user_id !== 0){
                const info = { name:_this.keywords, id: _this.user_id };
                localStorage.setItem('keywords', JSON.stringify(info));

              }
              _this.$emit('changePrice',true)
              _this.payButton= res.data.data.balance>0?false:true;
            }else if(res.data.status===500){
               _this.userMes="";
              _this.user_id=0;
              _this.payButton=true;
            }                  
          })
        .catch(result => {
            return false;
          })
    },
    discountButton(){  
      this.icon=0;
      this.showNumber=0;
      if(this.discountNumber){
        this.formDiscount.price=""
        this.discountRequest(2,this.formDiscount.price)
      }else{
         this.showdiscount=true;
      }
    },
    discountRequest(number,dis){
      console.log(number)
      const _this=this;
      const foundingLoId=this.$route.query.foundingLoId && this.$route.query.foundingLoId;
      const message={
        is_discount:number,
        grade_ratio:dis,
        founding_log_id:foundingLoId,
      }
      orderDiscount(message)
        .then((res) => {
          if(res.data.status===200){
            _this.discountNumber=dis;
            _this.showdiscount=false;
            _this.$refs.son.form.price = '';
            this.$emit('changePrice',true)   
          }             
          })
        .catch(result => {
            return false;
          })
    },
    finishRequest(){
      if(this.pay_status){
        this.$router.push('/yingye');
        return
      }
      const foundingLoId=this.$route.query.foundingLoId && this.$route.query.foundingLoId;
       const _this=this;
       finish({foundingLoId:foundingLoId})
        .then((res) => {
          if(res.data.status===200){
            _this.$message.success('结算成功',1.5); 
            _this.socketApi.websocketsend({"key": "jiezhangAction",value:{token: Cookies.get("Access-Token"),founding_log_id:foundingLoId} }); 
            _this.pay_status = true
            _this.$router.push('/yingye');
          }             
          })
        .catch(result => {
            return false;
          })
    },
    dianCai(){ //继续diancai
      this.$emit("cancel", false);
    },
     changeAddModal(){//关闭新增modal
     this.showAdd=false;
    },
    changeRechargeModal(){//关闭充值modal
      this.showRecharge=false;
    },
    qvMo(event){
      // event.returnValue = false;
       this.shownum=true;
    },
    inputSao(){
      console.log(this.saoYing)
    },
     updateNum(num){ //键盘
      this.saoYing = num;
    },
    updateNums(num){
     this.forms.price = num;
    },
    focusInput(event){ //扫码金额聚焦
      this.shownum=true;
        this.$nextTick(() => {
          this.$refs.numInput.focus();
          // this.$refs.numInput.currentTarget.select();
      }); 
    },
    clickSure(){
      this.shownum=false;
      this.$nextTick(() => {
          this.$refs.input.focus();
      }); 
    },
     payRequest(way){ //扫码请求支付
      const _this=this; 
      const foundingLoId=this.$route.query.foundingLoId && this.$route.query.foundingLoId;
      const message={
        foundingLoId:foundingLoId,
        pay_type:this.type_name,  //付款方式
        auth_no:this.form.price,
        current_price:parseFloat(_this.saoYing),  //付款金额
      }  
       orderpay(message)
            .then((res) => {
              if(res.data.status===200){
                const paylog_id=res.data.data.paylog_id  && res.data.data.paylog_id;
                 _this.paylog_id=paylog_id;
                 _this.form.price=""
               _this.orderStatusRequest(paylog_id,way);//请求获取支付状态              
              }else if(res.data.status===500){
                  _this.form.price=""
              }                  
            })
            .catch(result => {
              return false;
          })
    },
    orderStatusRequest(id,way){
      const _this=this;
      orderpaystatus({paylog_id:id})
        .then((res) => {
          if(res.data.status===200){
          _this.showNumber=2;
          const listItem=res.data.data.status  && res.data.data.status; 
           _this.icon= res.data.data.status  &&    res.data.data.status;
           _this.speech_path= res.data.data.speech_path && res.data.data.speech_path;
           _this.data_state=res.data.data.data_state && res.data.data.data_state;
           this.payPrice=this.saoYing;
           if(_this.icon===2){
              this.payWay='收款成功';
              if(this.speech_path){
                bound.play(this.speech_path)
            //      this.$nextTick(() => {
            //   _this.$refs.audio.play()
            // })
              }           
           }
          this.$emit('changePrice',true);
          }                   
          })
        .catch(result => {
            return false;
          })
    },
    changetotalPrice(){ //点击选中会员  改变整个订单价钱
       this.$emit('changePrice',true)   
    },
    clooseMember(){ //关闭会员modal
      this.showMember=false;
    },
    closeVip(){ //关闭会员价格
      this.searchUser(2)
    },
    getpayStatus(number){
      console.log(JSON.stringify(number))
      this.showNumber=2;
      this.icon=number[1]&& number[1]
      this.payPrice=number[0] && number[0]
      this.payWay=number[2] && number[2]
      if(number[3]){
        this.$emit('changePrice',true) 
      }
    },
    hangButton(){  //点击挂账按钮
      this.icon=0;
      this.showNumber=0;
      this.showPayment=true;
    },
    hangQv(){ //挂账取消按钮
      this.formhang={
        name:'',
        mobile:'',
      }
    },
    hangSure(){ //挂账确定按钮
       this.$refs.ruleHang.validate(valid => {
          if (valid) {
             orderHang({
                name:this.formhang.name,
                mobile:this.formhang.mobile,
                hang_price:this.nopay_price,
                founding_log_id:this.$route.query.foundingLoId && this.$route.query.foundingLoId,
                is_hang:1
             })
              .then((res) => {
                  if(res.data.status===200){  
                      this.icon=2;
                      this.showNumber=2;
                      this.payWay="挂账成功";
                      this.payPrice=this.nopay_price;
                      this.$emit('changePrice',true)
                  }                
                  })
              .catch(result => {
                  return false;
                })
             
          } else {
            return false;
          }
       });  
    },
    freeButton(){ //优免按钮
      this.icon=0;
      this.showNumber=0;
     
      if(this.free_price !=0.00){
        orderFree({
          free_price:0.00,
          founding_log_id:this.$route.query.foundingLoId && this.$route.query.foundingLoId,
        }).then((res) => {
      if(res.data.status===200){ 
          this.$emit('changePrice',true) 
      } 
    }).catch(result => {return false;})
      }else{
         this.showpreferential=true;
      }
    },
    updateFree(num){ //优免键盘事件
      this.formfree.price=num
    },
    searchFree(){ //优免确定按钮
     orderFree({
        free_price:0.00,
        founding_log_id:this.$route.query.foundingLoId && this.$route.query.foundingLoId,
     })
        .then((res) => {
          if(res.data.status===200){  
            // this.icon=2;
            // this.showNumber=2;
            // this.payWay="减免成功";
            // this.payPrice=this.formfree.price;
            // this.formfree.price="";
            // this.$emit('changePrice',true) 
            }                
        })
        .catch(result => {
            return false;
          })
    },
    updateDiscount(num){ //折扣键盘
      this.formDiscount.price=num
    },//
    searchDiscount(){ //自定义折扣确定按钮
      this.discountRequest(1,this.formDiscount.price)
    },
    infocusInput(){ 
      if(this.showNumber===1 && this.shownum==false){
           this.$nextTick(() => {
          this.$refs.input.focus();
      });
      }
    },
    clickCash(){ //点击现金按钮
      this.icon=0;
      this.showNumber=0;
      this.showMoney=true;
    },
    click_wx_zfb(type){
      this.icon=0;
      this.showNumber=0;
      this.showWXZFB = true
      this.wx_zfb = type
    },
    updateDetail(){
      this.$emit('changePrice',true) 
    },
    // handleInputBlur(e){
    //   this.index = e.srcElement.selectionStart;
    // }
    insertInputTxt(){
      var elInput = document.getElementById('input');
      var startPos = elInput.selectionStart;
      var endPos = elInput.selectionEnd;
      this.index=startPos;
      console.log( this.index)

    }
  },
};
</script>
<style lang="less" scoped>
.content{
  display: flex;
  padding:2vh 1vw;
  .tableLeft{
    width:35vw;
    .top{
      height:45vh;
      background: #ffffff;
      position: relative;
      border:1px solid #d0d0d0;
      /deep/table{
        border: 0 !important;
      }
      /deep/.ant-table-wrapper{
        height:50vh;
      }
    }
    .center{
      width:100%;
      height:3.5vh;
      border-left:1px solid #d0d0d0;
      border-right:1px solid #d0d0d0;
    }
    .bottom{
      margin-bottom:1vh;
      height:15vh;
    }   
  }
  .tableRight{
     width:44vw;
     margin:0 0.5vw;
     .delete{
       color:#ff6600;
     }
     .pay{
       overflow: hidden;
       width:100%;
      //  margin:0 1.5vw;
      padding:0 1.5vw;
       .everyButton{
         font-size: 2.4vh;
         height:8vh;
         width:18vw;
         background: white;
         border:1px solid rgba(208, 208, 208, 1) ;
         text-align: center;
         line-height:8vh;
         border-radius: 6px;
         cursor: pointer;
       }
       .everyButton:nth-child(1){
         float: left;
         background: #545454;
         color:#ffc300;
         border:1px solid #545454 ;
       }
       .everyButton:nth-child(2){
          float: right;
         background: #3293ff;
         color: #fff;
         border:1px solid #3293ff ;
       }
    
     }
     .member{
        background: #c9caca;
        padding:1.5vh 0 0.5vh 0;
        border-radius: 4px;
        .name{
          padding:0.5vh 0.5vw;
          font-size: 1.8vh;
          line-height: 3.5vh;
        }
        .message{
          display: flex;
          justify-content: space-between;
          padding:0.5vh 0.5vw;
          font-size: 1.8vh;
          line-height: 3.5vh;
          .normal{
            width:12vw;
          }
          .orange{
            color:#ff6600;  
          }        
          }         
     }
  }
  .tableFinally{
    ul{
      width:8vw; 
      li{
        background: rgba(56, 56, 56, 1);
        height:7vh;
        width:8vw;
        line-height: 7vh;
        text-align: center;
        border-bottom: 1px solid rgba(208, 208, 208, 1) ;
        font-size: 2vh;
        color:white;
        cursor: pointer;
      }
      .discount{
        position: relative;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
         .replace-item {
            right: 0;
            top: 0;
            position: absolute;
            font-size: 12px;
            color: #ffffff;
            .jiao{
              position: absolute;
              top:-3.5vh;
              right: -1vw;
              background: #ff685d;
              width: 1.8vw;
              height: 10vh;
              transform: rotate(-45deg);
              -webkit-transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -ms-transform: rotate(-45deg);;
              padding:0;
            }
            .desc {
              position: absolute;
              top:-2.2vh;
              right: 0.2vw;
              transform: rotate(50deg);
              -webkit-transform: rotate(50deg);
              -moz-transform: rotate(50deg);
              -ms-transform: rotate(50deg);
              font-size: 10px;
            }
        }   
      }
    }
  }
  .contents{
    height:35vh;
  }
  .changecontent{
    display: flex;
    align-items: center;
    justify-content: center;
    margin:0 auto;
    background: #ffffff;
    height:35vh;
    width:44vw;
    border: 1px solid rgba(208, 208, 208, 1) ;
    .sao{
     position: relative;
      width:44vw;
      height: 30vh;
      display: flex;
      align-items: center;
      justify-content: center;
       img{
        //  width:43vw;
         height: 30vh;
        //   position: absolute;
        //  left:0;
        //  top: 0;
         z-index: 2;
       }
       .input{
         width:5vw;
         position: absolute;
         left:0;
         bottom: 0;
         z-index: 3;
         opacity: 0;
       }
    }
    /deep/.anticon{
      font-size: 10vh;
     }
    .payway{
      .font{
       font-size: 3vh;
       text-align: center;
       color:#a6a6a6;
      }
    }
  }
}
 .bottom_b {
    color: white;
    display: flex;
    background-color: rgba(51, 51, 51, 1);
    justify-content: space-between;
    .other button {
      width: 12vw;
      height: 5.6vh;
      margin: 0.8vh 0 0.8vh 1.5vw;
      padding: 0.2vh 0.5vw 0.2vh 0.5vw;
      border-radius: 0.2vw;
      text-align: center;
      font-size: 1.1vw;
      color: black;
      font-weight: bold;
      background: #e4e4e4;
    }
    .button{
      display: flex;
      margin-right: 0.5vw;
    }
    .time {
      width: 10vw;
      height: 5.6vh;
      line-height: 5.5vh;
      margin: 0.8vh 0 0.8vh 1.5vw;
      padding: 0.2vh 0.5vw 0.2vh 0.5vw;
      border-radius: 0.2vw;
      text-align: center;
      font-size: 1.8vh;
      color: #e4e4e4;
      background-color:#595758;
    }
    .dian{
      background-color: rgba(42, 130, 228, 1);
    }
    .jie{
      background-color: rgba(255, 102, 0, 1);
    }
    .lock {
     :hover{
      background-color: #e4e4e4;
      color: #333333;
      cursor: pointer;
     }
    }
   .print {
     :hover{
      background-color: #ff0000;
      color: #e4e4e4;
      cursor: pointer;
     }
    }
    .right{
    position: absolute;
    right: 1vw;
    }
  }
  .amount{
      display: flex;
      flex-wrap: wrap;
      font-size: 1.8vh;
      border: 1px solid rgba(208, 208, 208, 1) ;
      border-bottom: 0 ;
      background: #ffffff;
      height:12vh;
      // .yingshou{  
      //   }
        .daishou{
          color:rgba(42, 130, 228, 1);
          font-weight: bold;
          font-size: 2.2vh;
        }
       li{
         width:21vw;
         padding: 1.2vh 1vw;
       }
     }
.impor{
  font-size: 2.0vh;
}     
ul{
  margin-bottom: 0;
}
/deep/.ant-modal {
  width: 90vw !important;
}
/deep/.ant-modal-content,
/deep/.ant-modal-header {
  background-color: rgba(237, 238, 242, 1);
}
/deep/.ant-modal-body {
  padding: 1.5vh 0vw 0 0;
}
/deep/.ant-modal-footer {
   display: none;
}
/deep/.ant-btn-primary {
  border-color: rgba(255, 102, 0, 1);
  background-color: rgba(255, 102, 0, 1);
  height:3.5vh;
}
/deep/.ant-modal-footer button + button {
  margin-left: 2vw;
}
/deep/.ant-modal-title {
  font-size: 2.0vh;
  font-weight: bold;
}
/deep/.ant-form-item-required::before {
  color: rgba(255, 102, 0, 1);
}
/deep/.ant-table-thead > tr > th{
  text-align: center;
  background: rgba(237, 238, 242, 1);
  font-size: 1.8vh;
  padding:0.7vh 0.7vw;
  font-weight: 550;
   border-color: rgba(208, 208, 208, 1); 
} 
/deep/.ant-table-tbody > tr > td {
  font-size: 1.8vh;
  padding:0.7vh 0.7vw;
  border-color: rgba(208, 208, 208, 1);
  background-color: white;
}
/deep/table{
   border-color: rgba(208, 208, 208, 1) !important; 
}
/deep/.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
 background: white;
}
/deep/.ant-pagination{
  margin-right:1vw;
}

/deep/.ant-input-group-addon{
 background: rgba(255, 102, 0, 1);
}
/deep/.name .anticon {
  color:rgba(255, 102, 0, 1);
  cursor: pointer;
}
/deep/.card .ant-btn-primary[disabled]    {
  border-color: rgba(208, 208, 208, 1);
  background-color: rgba(229, 229, 229, 1);
}
/deep/.zhe .ant-btn-primary[disabled]    {
  border-color: rgba(208, 208, 208, 1);
  background-color: rgba(229, 229, 229, 1);
}
/deep/.card .ant-btn {
  padding:0 1vw;
  height:3.5vh;
  font-size: 1.8vh;
 }
 .biao{
  font-size: 1.5vh;
  color:rgba(255, 0, 0, 1);
  font-style:italic;
  display: inline-block;
}
.finish{
  li{
    color:rgba(40, 172, 60, 1) !important
  }
} 
/deep/.ant-dropdown-menu-item{
  height: 6vh;
  line-height: 6vh;
  font-size: 1.8vh;
}

/deep/input::-webkit-input-placeholder {
    color: #999999;
  }
.way{
  display: flex;
  margin-bottom:2vh;
}
.zhe{  
  font-size: 1.8vh;
  margin-right:6vw;
  margin-top: 1vh;
  height:3.8vh;
  width:8vw;
  background: white;
  border:1px solid rgba(208, 208, 208, 1) ;
  text-align: center;
  line-height: 3.8vh;
  border-radius: 4px;
  cursor: pointer;
          position: relative;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          .replace-item {
            right: 0;
            top: 0;
            position: absolute;
            font-size: 12px;
            color: #ffffff;
            .jiao{
              position: absolute;
              top:-3.1vh;
              right: 0;
              background: #ff6600;
              width: 1.6vw;
              height: 7.5vh;
              transform: rotate(-45deg);
              -webkit-transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -ms-transform: rotate(-45deg);;
              padding:0;
            }
            .desc {
              position: absolute;
              top:-0.5vh;
              right: -0.1vw;
              transform: rotate(50deg);
              -webkit-transform: rotate(50deg);
              -moz-transform: rotate(50deg);
              -ms-transform: rotate(50deg);
              font-size: 1vh;
            }
        }   
      }
.saoma{
  font-weight: bold;
  color:rgba(255, 102, 0, 1);
  font-size: 2.2vh;
  position: relative;
  .ant-input{
    width:8vw;
    background:#fff ;
    color:rgba(255, 102, 0, 1);
    font-weight: bold;
    font-size: 2.0vh;
  }
  .ant-input:hover{
     border-color: #d9d9d9;
  }
  .code{
    position: absolute;
    left:-1vw;;
    top:6.4vh;
    background: #ffffff;
    padding:1vw 1vw;
    width:25vw;
    z-index: 3;
    border-radius:4px;
    // box-shadow: 3px 0px 0px #d9d9d9;
    /deep/.ant-btn{
      width:100%;
      background: rgba(255, 102, 0, 1);
      color:white;
      height:5vh;
      font-size: 2.0vh;
      }
  }
.code::before{
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-bottom-color: #ffffff;
  top: -2.1vh;
  left: 12vw;
}
  /deep/.ulJian{
     border:1px solid #b5b5b6;
     li{
       height:6vh;
       display: flex;
       align-items: center;
       justify-content: center;
       width:33.3%;
     }
  }
}
.orderUser{
  position: relative;
  .closebutton{
    position: absolute;
    right:1vw;
    top:0.8vh;
    font-size: 1.8vh;
    color:#ff6565;
    border:1px solid #ff6565;
    width:6vw;
    height:3.5vh;
    text-align: center;
    border-radius: 4px;
    line-height: 3.5vh;
    cursor: pointer;
  }
  ul{
    display: flex;
    height: 5vh;
    line-height: 5vh;
    font-size: 2.0vh;
    border: 1px solid #d0d0d0;
    border-bottom:0 ;
    background: white;
    li{
      width:21vw;
      flex-wrap: wrap;
      padding: 0 1vw;
    }
  }
}
.count{
  font-size: 2.8vh;
  line-height: 3vh;
  margin-top:4vh;
  margin-bottom:2vh;
}
.finishjie{
  width:12vw;
  height:5.5vh;
  background: rgba(255, 102, 0, 1);
  line-height: 5.5vh;
  font-size: 2.0vh;
  color:white;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  margin:0 auto;
}
.cash{
  /deep/.ant-input{
    width:15vw;
    height:4vh;
  }
}
/deep/.ant-form-item label{
  font-size: 1.8vh;
}
.zero{
  color:rgba(255, 102, 0, 1);
  font-weight: bold;
}
/deep/.ulJian{
  border:1px solid #d0d0d0;
  li{
    width:33.3%;
  }
}
.payway{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top:1.5vh;

}
/deep/.ant-modal {
  top:50px;
}
.bottom{
  /deep/.ant-table-wrapper{
    height:15vh;
  }
}  
/deep/.ant-empty-normal{
  margin:0;
}
.ways{
 height:11vh;
 border:1px solid #d0d0d0;
 border-top:0;
 display: flex;
 align-items: center;
 justify-content: center;
 position: relative;
 .zhes{
   position: absolute;
   left: 0;
   top:-1px;;
    height:11vh;
    width:100%;
    background: white;
 }
}
/deep/.ant-table-placeholder{
  border-right: 1px solid #d0d0d0 !important;
  border-left: 1px solid #d0d0d0;
  border-color:#d0d0d0 !important;
  height:15.5vh;
  line-height: 12vh;
}
/deep/.ant-input:hover{
    border-color:white;
}
.loading{
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
