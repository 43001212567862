<template>
  <div>
    <a-modal
      :visible="isShow"
      title="挂账"
      cancel-text="取消"
      ok-text="确定"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="content">
        <div class="detail">
          <div>订单号:&nbsp;&nbsp;{{order_no}}</div>
          <div class="jin">挂账金额:&nbsp;&nbsp;{{sheng?sheng:0.00}}元</div>
        </div>
         <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
         >
            <a-form-model-item ref="mobile" label="电话" prop="mobile">
              <a-input  v-model="form.mobile" placeholder="请输入挂账人电话"  @keyup.enter.native="handleOk"/>
            </a-form-model-item>
            <a-form-model-item ref="name" label="挂账人" prop="name">
              <a-input  v-model="form.name" placeholder="请输入挂账人姓名"  @keyup.enter.native="handleOk"/>
            </a-form-model-item>
         </a-form-model>
      </div>   
    </a-modal>
  </div>
</template>
<script>
import {orderHang} from '@/request/business.js';
export default {
  props: ["isShow",'sheng','data','order_no'], 
  data() {
    return {
      labelCol: { span: 4},
      wrapperCol: { span: 2},
      form: {
        name: '',
        mobile:''
      },
       rules:{
          name: [
          {  message: '请输入挂账人', trigger: 'change' },
          ],
          mobile: [
          { required: true, message: '请输入电话', trigger: 'change' },
          { pattern : /^1[3-9]{1}[0-9]{9}$/, message: '电话格式不正确'}
          ],
       }
     
    };
  },
  methods: {
    handleOk() {
      console.log(JSON.stringify(this.data))
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
              if(this.data.length>0){
                this.$message.warn('已存在过支付记录，不允许此操作',1.5)
              }else{
                this.hangRequest()
              } 
          } else {
            return false;
          }
       });  
    },
    handleCancel(){
      this.form.name="";
       this.form.mobile="";
      this.$emit('cancel',false)//关闭按钮;    
    },
    hangRequest(){
      const foundingLoId=this.$route.query.foundingLoId && this.$route.query.foundingLoId;
      const message={
        name:this.form.name,
        mobile:this.form.mobile,
        hang_price:this.sheng,
        founding_log_id:foundingLoId,
        is_hang:1
      }
      orderHang(message)
        .then((res) => {
          if(res.data.status===200){  
            this.form.name="";
            this.form.mobile="";
            this.$emit('cancel',false)//关闭按钮;     
            this.$emit('chooseData',true)//关闭按钮;   
          }                
          })
        .catch(result => {
            return false;
          })
    },
    

  }, 
};
</script>
<style lang="less" scoped>
/deep/.ant-modal-content {
  width: 35vw;
   background:rgba(237, 238, 242, 1);
}
/deep/.ant-modal-footer {
  text-align: center;
}
/deep/.ant-btn {
  width: 13vh;
  height: 6vh;
  font-size: 2.0vh;
}
/deep/.ant-modal-footer button + button {
  width: 7vw;
  height: 5vh;
  font-size: 1.8vh;
  border-radius: 0.5vh;
  color: white;
  margin-left: 5vh;
  background: rgb(238, 121, 12);
  border-color: rgb(238, 121, 12);
  width:13vh;
  height:6vh;
  font-size: 2.0vh;
}
/deep/.ant-modal-title{
  font-size: 2.0vh;
  font-weight: bold;
}
.content{
  font-size: 1.8vh;
}
/deep/.ant-form-item-control{
  width:15vw;
}
.detail{
  font-size:2.0vh;
  margin-bottom: 2vh;
  margin-left: 1vw;
  line-height: 3vh;
  div{
    margin-top:1.2vh;
  }
}
/deep/.ant-modal-header{
   background:rgba(237, 238, 242, 1);
}
/deep/.anticon svg{
  font-size: 2.8vh;
  color: #999999;
  margin-top: 1.5vh;
}
/deep/.ant-modal-body{
  padding:0 1.5vw ;
}
/deep/.ant-form-item label{
  font-size: 2.0vh;
  color:rgba(0, 0, 0, 0.65);
}
/deep/.ant-input{
  border-color:#999999 ;
  background:rgba(237, 238, 242, 1);
  height:6vh;
  font-size: 2.0vh;
  width:18vw;;
}
/deep/.ant-form-item-label {
  height:6vh;
  line-height: 6vh;
}
</style>