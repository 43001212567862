<template>
  <a-layout>
    <a-layout-header style="padding: 0; height: 5vh; line-height: 5vh">
      <Header :headername="headerName" />
    </a-layout-header>
    <a-layout-content>
      <div class="opentable">
        <div class="fistRow">
          <div class="leftLogo">
            <div class="logo">
              <img :src="oemData.upper_left_img" alt />
            </div>
            <div class="tableName">{{this.$route.query.table_name&&this.$route.query.table_name}}</div>
          </div>
          
            
          <div class="close" @click="btnBack">
            <a-icon type="close" />
          </div>
        </div>
        <div class="secondRow">
          <div class="leftConent">
            <ul class="orderList"  v-if="orderList && orderList.orderGoods && orderList.orderGoods.length">
              <li 
              v-for="(item,index) in orderList.orderGoods" 
              :key="index+'o'" 
              @click="ClickGoodsList(item,index)"
              :class="[activeIndex === index ? 'active' : '', 'everyList',item.is_print===1||item.is_print===0||item.is_print===2 ? 'printback' : '']"
               v-show="item.is_delete==1 ? true :false"
              >
                <div class="top">
                  <div class="name" >{{item.goods_name}}(<span>{{item.goods_price}}</span>元) </div>
                  <div class="print" v-if="item.is_print===1">已打印</div>
                  <div class="print notprinted" v-if="item.is_print===0">未打印</div>
                  <div class="print" v-if="item.is_print===2">打印失败</div>
                </div>
                  
                <div class="bottom " >
                  <div class="spec_content" v-if="!!item.spec_content">/{{item.spec_content}}/</div>
                  <div v-else></div>
                  <div class="totalPrice">
                    <span>{{item.goods_price}}</span>
                    x
                    <span>{{item.total_num?item.total_num:0}}{{item.goods_unit}}</span>
                    <span v-if="item.is_giving === 2">(赠)</span>
                     <span v-if="item.is_foodback === 2">(退{{item.refund_total_num}})</span>
                    =
                    <span>￥{{item.total_pay_price}}</span>
                  </div>
                </div>             
              </li>
            </ul>
            <div class="calculate">
              <span>未送金额:</span>
              <span class="red">￥{{noSend}}</span>
              <span>已送金额:</span>
              <span class="red">￥{{isSend}}</span>
            </div>
          </div>
          <div class="centContent">
            <ul class="type">
              <li class="mark"  @click="addButton" v-if="orderListEvery && orderListEvery.is_print==='' &&  orderListEvery.is_giving===1 && orderListEvery.is_weighing !==2">+</li>
              <li class="mark"  @click="cutButton" v-if="orderListEvery && orderListEvery.is_print==='' &&  orderListEvery.is_giving===1 && orderListEvery.is_weighing !==2">－</li>
              <li @click="halfButton" v-if="orderListEvery && orderListEvery.is_weighing !==2 && orderListEvery.is_print==='' && orderListEvery.is_giving !==2">半份</li>
              <li v-if="orderListEvery && orderListEvery.is_avoid===2" @click="avoidButton">忌口</li>
              <li v-if="orderListEvery && orderListEvery.is_practice===2" @click="practiceButton">做法</li>
              <li v-if="orderListEvery && orderListEvery.is_taste===2"  @click="tasteButton">口味</li>
              <li v-if="orderListEvery && (orderListEvery.is_print===1 || orderListEvery.is_print===2 || orderListEvery.is_print===0) && orderListEvery.is_giving===1"  @click="quitButton">退菜</li>
              <li v-if="orderListEvery && orderListEvery.is_print===''"  @click="deleteButton">删除</li>
              <li    @click="givingButton"  v-if="orderListEvery && orderListEvery.is_foodback===1 && orderListEvery.is_giving===1">赠送</li>
              <li v-if="orderListEvery && orderListEvery.is_giving===2" @click="cancelGiving">取消赠送</li>
              <li v-if="(orderListEvery && orderListEvery.is_print==='' &&  orderListEvery.is_giving===1 && orderListEvery.is_weighing !==2) || (orderListEvery && orderListEvery.is_weighing ==2)" @click="reviseButton">修改数量</li>
              <li @click="snapButton" >临时菜品</li>
              <li @click="detailButton">明细</li>
              <li @click="showRemark = true">送单备注</li>
            </ul>
          </div>
          <div class="rightContent">
            <menuList  @goodsData="getGoodsData" ref="child" :searchIds="this.form.username" :isShow="showkeyCode" :keyboards="keyboards" :form="form"  @getNumber="getcontent"/>
          </div>
        </div>
        <chooseModal :data="clickEveryGood" :isShow="showChooseModal" @cancel="changeShow" @chooseData="getChooseData"/>
        <avoidModal :isShow="showAvoidModal" :data="orderListEvery" @chooseData="getAvoideData"  @cancel="changeAvoidShow"/>
        <practiceModal :isShow="showPracticeModal" :data="orderListEvery" @chooseData="getPracticeData" @cancel="changePracticeShow"/>
        <tasteModal :isShow="showTasteModal" :data="orderListEvery" @chooseData="getTasteData" @cancel="changeTasteShow"/>
        <givingModal :isShow="showgivingModal" :data="orderListEvery"  @chooseData="getgivingData" @cancel="changeGivingShow"/>
        <givingCountModal :isShow="showGivingCountModal" :data="orderListEvery"  @cancel="changeGivingCountShow"  @chooseData="getgivingCountData" />
        <canGivingModal :isShow="showCanGivingModal" :data="orderListEvery" @cancel="chancanGiviShow" @chooseData="getCanGiving"/>
        <quitModal :isShow="showQuitModal" :data="orderListEvery" @chooseData="getQuit" @cancel="changeQuitShow"/>
        <quitCountModal :isShow="showQuitCouMo" :data="orderListEvery"  @cancel="changeQuitCouShow" @chooseData="getQuitCountData" />
        <reviseModal :isShow="showReviseModal" :data="orderListEvery"  @cancel="changeReviseShow" @chooseData="getRevisCountData"/>
        <detailModal :isShow="showDetailModal"  @cancel="changeDetailShow" :data="orderList"/>
        <snapModal :isShow="showSnapModal"  @cancel="changeSnapShow"  @chooseData="getSnapData(arguments)"/>
        <accountHintModal :isShow="showAccHiModal"  @cancel="changeAccHiShow" @turnOn="turnOnModal"/>
        <accountModal :isShow="showAccountModal"  @cancel="changeAccountShow" :data="orderList" @changePrice="updatedPrice" ref="account"/>
        <Weight :isShow="showWeight"  @cancel="changeWeightShow"  :data="clickEveryGood" @chooseData="getWeight"/>
        <halfTip :isShow="showHalf" :data="clickEveryGood" :num="num" @cancel="getHalfModal" @changeHalf="getHalf"/>
        <formatModal :isShow="showFormat" :data="clickEveryGood"/>
        <orderRemark :isShow="showRemark" @handleCancel="showRemark = false" @handleRemark="handleRemark" :remarText="remark" :remarkData="remarkData"></orderRemark>
      </div>
    </a-layout-content>
    <a-layout-footer style="padding:0;">
      <div class="bottom_b">
        <div class="lock">
          <div  :class="[disabled===true ? 'gray' : '','time']">
            <a-button :disabled="disabled"  @click="clickSend(1)"> {{disabled?"请求中":"先送(F7)"}}</a-button>
          </div>
          <div class="time" @click="clickSend(2)" :style="disabledBudan ? 'background:#f5f5f5;color:#c2c2c2' : ''">
             {{disabledBudan ? "请求中" : "补单不打印(F8)"}}
          </div>
        </div>        
        <div class="lockRight">
            <a-form-model ref="ruleForm" :model="form">
          <div class="input">
              <div class="search">
                 <a-input-search placeholder="菜品名称"   v-model="form.username" @focus="focusUsername()" @search="onSearch"/>
              </div>
              <div class="jianpan" @click="clickJian">
                <img :src="require('@/assets/键盘.svg')" alt="" />
              </div>   
          </div>
          </a-form-model>
          <div :class="[disabledJie===true ? 'gray' : '','time','account']">
             <a-button :disabled="disabledJie"  @click="showConfirm">{{disabledJie?"请求中":"结算(F5)"}}</a-button>
          </div>
          <div class="time finish" @click="finishSend(1)"  :style="disabledWanc ? 'background:#f5f5f5;color:#c2c2c2' : ''">
            {{disabledWanc ? "请求中" : "完成送单(F1)"}}
          </div>
        </div>      
    </div>
    </a-layout-footer>
  </a-layout>
</template>
<script>
////表格数据
import Header from "@/components/touTop/touTop.vue";
import { mapState } from "vuex";
import menuList from './modules/menulist.vue';
import chooseModal from './modules/chooseModal.vue';
import avoidModal from './modules/avoidModal.vue';
import practiceModal from './modules/practiceModal.vue';
import tasteModal from './modules/tasteModal.vue';
import givingModal from './modules/givingModal.vue';
import givingCountModal from './modules/givingCountModal.vue';
import canGivingModal from './modules/canGivingModal.vue';
import quitModal from './modules/quitModal.vue';
import quitCountModal from './modules/quitCountModal.vue';
import reviseModal from './modules/reviseModal.vue';
import detailModal from './modules/detailModal.vue';
import {orderDetail,orderCreate,goodsList , checkFoundingLogStatus} from '@/request/business.js';
import snapModal from './modules/snapModal.vue';
import accountModal from './modules/accountModal.vue';
import accountHintModal from './modules/accountHintModal .vue';
import Cookies from "js-cookie";
import keyCode from "@/components/keycode/keycode";
import Weight from './modules/weight.vue';
import halfTip from './modules/halfTip.vue';
import formatModal from './modules/format.vue'
import orderRemark from './modules/orderRemark.vue'
export default {
  components: {
    Header,  
    menuList, //商品列表
    chooseModal,
    avoidModal,
    practiceModal,
    tasteModal,
    givingModal,
    givingCountModal,
    canGivingModal, //取消赠送
    quitModal,
    quitCountModal,
    reviseModal,
    detailModal,
    snapModal,
    accountModal,
    accountHintModal, 
    keyCode,
    Weight,//称重页面
    halfTip,//半份
    formatModal,//多规格
    orderRemark // 送单备注
  },
  data() {
    return {
      headerName: "营业",
      orderList:{},//初始订单详情
      clickEveryGood:"",//点击商品列表每条数据 传给choosemodal
      showChooseModal:false, //chooseModal是否显示
      initialListLength:0,//初始订单详情列表个数
      eachGoodList:"",//点击商品列表每条数据 传给左边订单详情list
      activeIndex:0, //左边订单详情点击激活状态索引
      orderListEvery:[],//左边订单详情激活状态下的列表每项数据
      showAvoidModal:false, //忌口modal是否显示
      showPracticeModal:false, //做法Modal是否显示
      showTasteModal:false, //口味Modal是否显示
      showgivingModal:false, //赠送Modal是否显示
      showGivingCountModal:false,//修改赠送数量modal
      showCanGivingModal:false,//修改取消modal
      showQuitModal:false,//退菜modal
      showQuitCouMo:false,//退菜数量modal
      showReviseModal:false,//修改数量modal
      showDetailModal:false,//明细modal
      showSnapModal:false,//临时菜品modal
      showAccHiModal:false,//结算提示
      showAccountModal:false,//结算modal
      showWeight:false,//称重页面
      showFormat:false,//多规格
      noSend:0,//未送金额
      isSend:0,//已送金额
      // quitArr:[],//退菜数组
      againSend:false,//是否重新送单
      form: { //软件盘
         username:''
       },
      showkeyCode:false,//是否显示软件盘
      keyboards:'username',
      weight:'',//重量千克
      quitArrs:'',//退菜的原因
      showHalf:false,//半份提示
      num:'',//半时数量
      timer:'',//延时器
      disabled:false, //送单按钮禁用
      disabledBudan:false, // 补单不打印
      disabledWanc:false,  //完成送单
      disabledJie:false,//结算
      showRemark:false,  //送单备注
      remark:'',
      remarkData:[]
    };
  },
  computed: {
    ...mapState(["shop_id" , "oemData"]),
  },
  watch:{
    orderList:{
      handler(){
        const list=this.orderList && this.orderList.orderGoods;
        let sumNo=0;
        let sumYes=0;
        for(let i=0;i<list.length;i++){
          if(list[i].is_print===''){
              sumNo=(sumNo*1000+parseFloat(list[i].total_pay_price)*1000)/1000
          }else if(list[i].is_print !==""){
              sumYes=(sumYes*1000+parseFloat(list[i].total_pay_price)*1000)/1000
          }
        }
        this.noSend=sumNo;
        this.isSend=sumYes;
      },
      deep:true
    },
    form:{
      handler(){
        this.onSearch()
      },
      deep:true,
    }
  },
  methods: {
     accMul(num1,num2){ //乘法精确
      var m=0,s1=num1.toString(),s2=num2.toString(); 
      try{m+=s1.split(".")[1].length}catch(e){};
      try{m+=s2.split(".")[1].length}catch(e){};
      return Number(s1.replace(".",""))*Number(s2.replace(".",""))/Math.pow(10,m);
    },
    getcontent(val){
      this.form.username = val;
    },
     focusUsername() {
        this.keyboards = "username";
    },
    clickJian(){//点击软件盘
    this.showkeyCode = !this.showkeyCode;
    console.log( this.showkeyCode)

    },
    btnBack() {
      this.$router.push({path:"/yingye"})     
    },
    onSearch() {
      this.$refs.child.page = 1;
      this.$refs.child.goodsRequest(this.form.username);
    },
    getGoodsData(item){   //忌口，做法，口味modal数据
      // if(item.is_weighing==2){
      //   this.showWeight=true;
      //   this.clickEveryGood=item;
      //   this.eachGoodList=item;
      // }else if(item.is_weighing==1){
         if( item.is_taste===2 || item.is_practice===2 || item.spec_type == 20 || item.is_weighing==2){
          this.showChooseModal=true;
          this.clickEveryGood=item;
          this.eachGoodList=item;
        }else if( item.is_taste===1 && item.is_practice===1 && item.spec_type == 10){
          this.showChooseModal=false;
          this.clickEveryGood=item;
          this.eachGoodList=item;
          this.getChooseData()
        }
      // } 
      //  if( item.is_taste===2 || item.is_practice===2){
      //    this.showChooseModal=true;
      //    this.clickEveryGood=item;
      //    this.eachGoodList=item;
      // }else if( item.is_taste===1 && item.is_practice===1){
      //      this.showChooseModal=false;
      //       this.clickEveryGood=item;
      //     this.eachGoodList=item;
      //      this.getChooseData()
      // }
    },
    orderDetail(){  //初始状态订单请求
    const foundingLoId=this.$route.query.foundingLoId && this.$route.query.foundingLoId;
    this.disabledJie=true
       orderDetail({
         foundingLoId:foundingLoId,
         })
            .then((res) => {
                this.disabledJie=false
              if(res.data.status===200){
                const list =res.data.data && res.data.data;
                this.initialListLength=list.orderGoods.length;
                this.orderList=list; 
                if(!this.initialListLength){
                  this.orderList.orderGoods = []
                  goodsList({noPage:'yes', is_relation_people:2}).then(resData => {
                    console.log('resData---',resData);
                    if(resData.data.status == 200){
                      let dataList = resData.data.data && resData.data.data.list 
                      dataList.forEach(item => {
                        let goods = {
                          order_goods_id:0,
                          goods_id: item.goods_id,//id
                          goods_name: item.goods_name,
                          goods_unit: item.goods_unit,
                          spec_type: item.spec_type,
                          is_print:'',
                          avoid: '',//忌口
                          practice: '',//做法
                          taste: '',//口味
                          total_num: this.orderList.people,//数量
                          is_foodback:1,//商品是否退菜（1正常2退菜
                          foodback_desc:"",//商品退菜的理由
                          is_delete:1,//商品是否删除（1正常2删除）
                          delete_desc:"",//商品删除理由
                          is_giving:1,//商品是否赠送（1正常2赠送）
                          giving_desc:"",//商品赠送理由
                          is_taste: item.is_taste,//是否开启口味
                          is_practice: item.is_practice,//是否开启做法
                          is_avoid: item.is_avoid,//是否开启忌口
                          giving_num:0,//赠送的数量
                          refund_total_num:0,//退菜的数量
                          createdtime:'',
                          is_weighing: item.is_weighing,
                          new_refund_num:0,
                        }
                        if(item.spec_type == 20){
                          JSON.parse(item.spec_content).forEach(ele => {
                            if(ele.goods_no == item.relation_people_content.goods_no){
                              goods.goods_price = (ele.spec_price * 1).toFixed(2)
                              goods.spec_content = ele.spec_name
                              goods.total_price = (ele.spec_price * 1 * this.orderList.people).toFixed(2)
                              goods.total_pay_price = (ele.spec_price * 1 * this.orderList.people).toFixed(2)
                            }
                          })
                        }else{
                          goods.goods_price = item.goods_price
                          goods.spec_content = ''
                          goods.total_price = (item.goods_price * 1 * this.orderList.people).toFixed(2)
                          goods.total_pay_price =( item.goods_price * 1 * this.orderList.people).toFixed(2)
                        }
                        this.orderList.orderGoods.unshift(goods)
                      });
                      this.orderListEvery = this.orderList.orderGoods[0];
                      this.activeIndex = 0;
                    }
                  })
                }
                console.log(JSON.parse(list.buyer_remark));
                if(list.buyer_remark != '[]'){
                  let arr = []
                  for(let key in JSON.parse(list.buyer_remark)){
                    arr.push(JSON.parse(list.buyer_remark)[key])
                  }
                  this.remarkData = arr
                  console.log(this.remarkData);
                }
                
                this.orderListEvery=list.orderGoods[0] && list.orderGoods[0];
                if(this.$route.query.handle == 'settlement'){
                  this.showConfirm()
                }
              }else {
                this.$message.error(res.data.message)
              }           
            })
            .catch(result => {
              return false;
          })
    },
    ClickGoodsList(item,index){
      this.activeIndex=index;
      this.orderListEvery=item;
    },
    async clickSend(number){
      if(this.disabled || this.disabledBudan){
        return
      }
       const chuLength=this.initialListLength;
       const jieLength=this.orderList.orderGoods.length-chuLength;
       const chuanArr=this.orderList.orderGoods 
       let checkData = await checkFoundingLogStatus({founding_log_id : this.$route.query.foundingLoId})
       let foundingLoId = checkData.data.data.founding_log_id
        const message={
          "founding_log_id": foundingLoId,
          "is_print":number,
          buyer_remark : this.remark,
          order:chuanArr
        }
        const _this=this;
        if(number == 1){
          this.disabled=true;
        }else{
          this.disabledBudan = true
        }
       orderCreate(message)
            .then((res) => {
              //  this.disabled=false;
              if(res.data.status===200){
                this.$message.success('送单成功', 1.5);
                this.remark = ''
                this.$refs.child.goodsRequest(this.form.username);
                 this.againSend=false;//关闭重新送单
                // alert(JSON.stringify(this.orderList.orderUser.mobile))
                if(this.orderList && this.orderList.orderUser){
                  if(this.orderList.orderUser.mobile){
                     _this.$refs.account.searchUser(1);
                  }
                }
                if(foundingLoId != this.$route.query.foundingLoId){
                  let query = this.$route.query
                  query.foundingLoId = foundingLoId
                  this.$router.push('/yingye')
                  this.$router.push({
                    path: "/openTable",
                    query
                  });
                }
                if(number===1){
                  this.socketApi.websocketsend({
                    "key": "diancaiAction",
                    value:{
                      token: Cookies.get("Access-Token"),
                      founding_log_id:foundingLoId ,
                      devicenums: res.data.data.deviceNumber
                    } 
                  }); 
                }
                
                 _this.orderDetail();
                // clearTimeout(this.timer);
                // this.timer=setTimeout(function(){
                   
                // },500)       
              }          
            })
            .finally(err => {
                this.disabled = false
                this.disabledBudan = false
            })
            setTimeout(() => {
              this.disabled = false
              this.disabledBudan = false
            },8000)
    },
    changeShow(){ //改变忌口做法口味modal状态
      this.showChooseModal=false;
    },
    changeAvoidShow(){ //改变忌口modal框状态
      this.showAvoidModal=false;
    },
    changePracticeShow(){ //改变做法modal框状态
      this.showPracticeModal=false;
    },
     changeTasteShow(){ //改变口味modal框状态
      this.showTasteModal=false;
    },
    changeGivingShow(){ //关闭改变赠送modal框状态
       this.showgivingModal=false;     
    },
    changeGivingCountShow(){ //关闭修改赠送数量modal
       this.showGivingCountModal=false;
    },
    changeQuitShow(){ //关闭退菜原因modal
      this.showQuitModal=false;
    },
    changeQuitCouShow(){ //关闭退菜计算modal
       this.showQuitCouMo=false;
    },
    changeReviseShow(){ //关闭修改数量modal
      this.showReviseModal=false;
    },
    changeDetailShow(){  //关闭明细modal
      this.showDetailModal=false;
    },
    changeSnapShow(){ 
      this.showSnapModal=false;
    },
    changeAccountShow(){ //关闭结算详情
        this.showAccountModal=false;
        this.keyUp()
    },
    changeAccHiShow(){//关闭结算提示MODAL
      this.showAccHiModal=false;
      this.keyUp()
    },
    changeWeightShow(){
      this.showWeight=false;
    },
    getChooseData(data){
      if(this.eachGoodList.is_weighing==2){
        this.weight = data.weight
      }
      console.log(this.eachGoodList.is_weighing) //添加左边订单详情列表
      console.log(JSON.stringify(this.eachGoodList))
      if(this.eachGoodList && this.eachGoodList.limited_stock_total !=0.00){
        this.orderList.orderGoods.unshift({
        order_goods_id:0,
        goods_id:this.eachGoodList.goods_id,//id
        goods_name:this.eachGoodList.goods_name,
        goods_unit:this.eachGoodList.goods_unit,
        goods_price: data ? data.goods_price : this.eachGoodList.goods_price,
        spec_type: data ? data.spec_type : this.eachGoodList.spec_type,
        is_print:'',
        avoid:data?data.avoid:'',//忌口
        practice:data?data.practice:'',//做法
        taste:data?data.taste:'',//口味
        spec_content:data?data.spec_content:'',//规格
        total_num:this.eachGoodList.is_weighing==2?(parseFloat(this.weight)).toFixed(2):1,//数量
        total_price: this.eachGoodList.is_weighing==2?this.weight*(data ? data.goods_price : this.eachGoodList.goods_price):1* (data ? data.goods_price : this.eachGoodList.goods_price)     ,//商品原价
        total_pay_price:this.eachGoodList.total_pay_price?this.eachGoodList.total_pay_price:((data ? data.goods_price : this.eachGoodList.goods_price)*(this.eachGoodList.is_weighing==2?this.weight:1)).toFixed(2),//商品的打折后的价钱
        // total_num:1,//数量
        // total_price: 1* this.eachGoodList.goods_price     ,//商品原价
        // total_pay_price:this.eachGoodList.total_pay_price?this.eachGoodList.total_pay_price:this.eachGoodList.goods_price*1,//商品的打折后的价钱
        // price:this.eachGoodList.goods_price,//商品的原价
        // real_price:this.eachGoodList.goods_price,//商品的真实价格
        is_foodback:1,//商品是否退菜（1正常2退菜
        foodback_desc:"",//商品退菜的理由
        is_delete:1,//商品是否删除（1正常2删除）
        delete_desc:"",//商品删除理由
        is_giving:1,//商品是否赠送（1正常2赠送）
        giving_desc:"",//商品赠送理由
        is_taste:this.eachGoodList.is_taste,//是否开启口味
        is_practice:this.eachGoodList.is_practice,//是否开启做法
        is_avoid:this.eachGoodList.is_avoid&&this.eachGoodList.is_avoid,//是否开启忌口
        avoidArr:this.eachGoodList.avoid, //忌口初始数组
        practiceArr:this.eachGoodList.practice,//做法初始数组
        tasteArr:this.eachGoodList.taste,//口味初始数组
        giving_num:0,//赠送的数量
        refund_total_num:0,//退菜的数量
        createdtime:'',
        is_weighing:this.eachGoodList.is_weighing,
        new_refund_num:0,
      }); 
      this.orderListEvery= this.orderList.orderGoods[0];
      this.activeIndex=0;
      }else{
     if(this.eachGoodList && this.eachGoodList.stock_total===0 && this.eachGoodList.is_oversold===1){
       this.$message.warn('该商品库存为0,请添加库存',1.5)
     }else if(this.eachGoodList && (this.eachGoodList.stock_total!==0 || this.eachGoodList.is_oversold===2)){
        this.orderList.orderGoods.unshift({
        order_goods_id:0,
        goods_id:this.eachGoodList.goods_id,//id
        goods_name:this.eachGoodList.goods_name,
        goods_unit:this.eachGoodList.goods_unit,
        goods_price:data ? data.goods_price : this.eachGoodList.goods_price,
        spec_type: data ? data.spec_type : this.eachGoodList.spec_type,
        is_print:'',
        avoid:data?data.avoid:'',//忌口
        practice:data?data.practice:'',//做法
        taste:data?data.taste:'',//口味
        spec_content:data?data.spec_content:'',//规格
        total_num:this.eachGoodList.is_weighing==2?(parseFloat(this.weight)).toFixed(2):1,//数量
        total_price: this.eachGoodList.is_weighing==2?this.weight*(data ? data.goods_price : this.eachGoodList.goods_price):1* (data ? data.goods_price : this.eachGoodList.goods_price)     ,//商品原价
        total_pay_price:this.eachGoodList.total_pay_price?this.eachGoodList.total_pay_price:((data ? data.goods_price : this.eachGoodList.goods_price)*(this.eachGoodList.is_weighing==2?this.weight:1)).toFixed(2),//商品的打折后的价钱
        // total_num:1,//数量
        // total_price: 1* this.eachGoodList.goods_price     ,//商品原价
        // total_pay_price:this.eachGoodList.total_pay_price?this.eachGoodList.total_pay_price:this.eachGoodList.goods_price*1,//商品的打折后的价钱
        // price:this.eachGoodList.goods_price,//商品的原价
        // real_price:this.eachGoodList.goods_price,//商品的真实价格
        is_foodback:1,//商品是否退菜（1正常2退菜
        foodback_desc:"",//商品退菜的理由
        is_delete:1,//商品是否删除（1正常2删除）
        delete_desc:"",//商品删除理由
        is_giving:1,//商品是否赠送（1正常2赠送）
        giving_desc:"",//商品赠送理由
        is_taste:this.eachGoodList.is_taste,//是否开启口味
        is_practice:this.eachGoodList.is_practice,//是否开启做法
        is_avoid:this.eachGoodList.is_avoid&&this.eachGoodList.is_avoid,//是否开启忌口
        avoidArr:this.eachGoodList.avoid, //忌口初始数组
        practiceArr:this.eachGoodList.practice,//做法初始数组
        tasteArr:this.eachGoodList.taste,//口味初始数组
        giving_num:0,//赠送的数量
        refund_total_num:0,//退菜的数量
        createdtime:'',
        is_weighing:this.eachGoodList.is_weighing,
        new_refund_num:0,
      }); 
      this.orderListEvery= this.orderList.orderGoods[0];
      this.activeIndex=0;
     } 
     }  
   },
   getWeight(content){
    this.weight=content;
    if(this.eachGoodList &&(this.eachGoodList.is_taste===2 || this.eachGoodList.is_practice===2)){
      this.showChooseModal=true;
    }else{
      this.getChooseData();
    }
    
   },
   getAvoideData(content){
    const list=  this.orderList &&  this.orderList;
    list.orderGoods[this.activeIndex].avoid=content;                                                                                                                      
   },
   getPracticeData(content){
    //  console.log(JSON.stringify(content));
    const list=  this.orderList &&  this.orderList;
    list.orderGoods[this.activeIndex].practice=content;
    // console.log(JSON.stringify(list));
   },
   getTasteData(content){
      // console.log(JSON.stringify(content));
    const list=  this.orderList &&  this.orderList;
    list.orderGoods[this.activeIndex].taste=content;
    //  console.log(JSON.stringify(list));
   },
   getgivingData(content){  //赠送
    // console.log(JSON.stringify(content));
    //this.showGivingCountModal=true;
    const list=  this.orderList &&  this.orderList;
    // console.log(JSON.stringify(list));
    list.orderGoods[this.activeIndex].giving_desc=content;
    list.orderGoods[this.activeIndex].is_giving=2;  //2为增菜
    list.orderGoods[this.activeIndex].total_pay_price=0;//价格重置为0
     this.againSend=true;//重新送单
    // console.log(JSON.stringify(list));
   },
   getgivingCountData(content){
      console.log(JSON.stringify(parseInt(content)));
      const list=  this.orderList &&  this.orderList;
      list.orderGoods[this.activeIndex].is_giving=2;  //2为增菜
      list.orderGoods[this.activeIndex].giving_num = parseInt(content);//赠送数量
      const price= list.orderGoods[this.activeIndex].total_price;//商品总价 
      const you=list.orderGoods[this.activeIndex].goods_price * list.orderGoods[this.activeIndex].giving_num; //赠送价格
      list.orderGoods[this.activeIndex].total_pay_price=price - you;
      // console.log(JSON.stringify(list));
   },
   getCanGiving(content){   //点击取消赠送按钮  改变列表数据
   if(content===true){
    const list=  this.orderList &&  this.orderList;
    console.log(JSON.stringify(list))
    list.orderGoods[this.activeIndex].is_giving=1;  //2为增菜
    // list.orderGoods[this.activeIndex].total_pay_price=list.orderGoods[this.activeIndex].total_price;//取消赠送价格  giving_desc

      list.orderGoods[this.activeIndex].total_pay_price=(this.accMul(list.orderGoods[this.activeIndex].goods_price,list.orderGoods[this.activeIndex].total_num)).toFixed(2);//取消赠送价格  giving_desc
     list.orderGoods[this.activeIndex].giving_desc='';//赠送原因重置为空
     this.againSend=true;//重新送单
   }
   },
   getQuit(content){ //退菜原因
    this.quitArrs=content
    console.log(this.quitArrs)
    // const list=  this.orderList &&  this.orderList;
    // list.orderGoods[this.activeIndex].foodback_desc=content;
    // list.orderGoods[this.activeIndex].is_foodback=2;  //2为退菜
    // // list.orderGoods[this.activeIndex].total_pay_price=0;//价格重置为0；
    // this.againSend=true;//重新送单
    // // this.quitArr=[];
    // // this.quitArr.push(list.orderGoods[this.activeIndex].goods_id);
    // // console.log(JSON.stringify( this.quitArr))
    this.showQuitCouMo=true;
   },
   getQuitCountData(content){// 修改退菜数据
    console.log(parseFloat(content)) 
    const list=  this.orderList &&  this.orderList;
    list.orderGoods[this.activeIndex].is_foodback=2;  //2为退菜
    list.orderGoods[this.activeIndex].foodback_desc=this.quitArrs;
    // console.log(JSON.stringify(list.orderGoods[this.activeIndex]))
    // console.log(parseFloat(list.orderGoods[this.activeIndex].refund_total_num))
     list.orderGoods[this.activeIndex].new_refund_num=parseFloat(parseFloat(content).toFixed(2))
    list.orderGoods[this.activeIndex].refund_total_num= parseFloat((parseFloat(content)+parseFloat(list.orderGoods[this.activeIndex].refund_total_num?list.orderGoods[this.activeIndex].refund_total_num:0)).toFixed(2));//退数量
    const shengNum=parseFloat(list.orderGoods[this.activeIndex].total_num)-parseFloat(content);
    console.log(parseFloat(list.orderGoods[this.activeIndex].total_num))
      console.log(parseFloat(content))
      console.log(shengNum)
    list.orderGoods[this.activeIndex].total_num= parseFloat((shengNum).toFixed(2));
    list.orderGoods[this.activeIndex].total_pay_price=(this.accMul(list.orderGoods[this.activeIndex].goods_price,parseFloat(shengNum.toFixed(2)))).toFixed(2)
    this.againSend=true;//重新送单
    console.log("tuicai------------",JSON.stringify( list.orderGoods[this.activeIndex]),);
   },
   getRevisCountData(content){ //修改菜品数量
    const list=  this.orderList &&  this.orderList;
    list.orderGoods[this.activeIndex].total_num = parseFloat(parseFloat(content).toFixed(2));//修改菜品数量
    list.orderGoods[this.activeIndex].total_pay_price = (this.accMul(list.orderGoods[this.activeIndex].goods_price,parseFloat(content).toFixed(2))).toFixed(2); //打折后的价格
    list.orderGoods[this.activeIndex].total_price=(this.accMul(list.orderGoods[this.activeIndex].goods_price,parseFloat(content).toFixed(2))).toFixed(2);//原价格
    this.againSend=true;//重新送单
    console.log(JSON.stringify(list.orderGoods[this.activeIndex]))
   },
   getSnapData(content){
      console.log(JSON.stringify(content[1]))
      content[1].total_pay_price=content[1].total_num * content[1].goods_price;
      content[1].is_print='';
      content[1].is_giving=1;
      content[1].is_foodback=1;
      content[1].order_goods_id=0;
      content[1].is_delete=1;
      content[1].goods_unit='';
      content[1].foodback_desc='';
      content[1].delete_desc='';
      content[1].giving_desc='';
      content[1].total_num=parseFloat(content[1].total_num)
      this.orderList.orderGoods.unshift(content[1]);
      this.orderListEvery= this.orderList.orderGoods[0] &&  this.orderList.orderGoods[0];
   },
   getHalfModal(){//关闭half提示框
    this.showHalf=false;
   },
   cancelGiving(){ //点击取消赠送按钮
     this.showCanGivingModal=true;
   },
   chancanGiviShow(){
    this.showCanGivingModal=false; //点击取消赠送modal
   },
   avoidButton(){//点击忌口按钮
    this.showAvoidModal=true;
   },
   practiceButton(){ //点击做法按钮
     this.showPracticeModal=true;
   },
   tasteButton(){ //点击口味按钮
      this.showTasteModal=true;
   },
   deleteButton(){ //删除按钮
    this.orderList.orderGoods.splice(this.activeIndex, 1);
    this.orderListEvery= this.orderList.orderGoods[0];   
   },
   givingButton(){//赠送按钮
   this.showgivingModal=true;
   },
   quitButton(){//退菜按钮
   this.showQuitModal=true;
   },
   reviseButton(){//修改数量按钮
     this.showReviseModal=true;
   },
   detailButton(){//明细按钮
    this.showDetailModal=true;
   },
   snapButton(){//临时菜品按钮
   this.showSnapModal=true;
   },
   accountButton(){//结算按钮
     this.showAccountModal=true;
   },
   addButton(){ //增加按钮
    const list=  this.orderList &&  this.orderList;
    list.orderGoods[this.activeIndex].total_num ++;//修改菜品数量
    list.orderGoods[this.activeIndex].total_num=(list.orderGoods[this.activeIndex].total_num).toFixed(2)
    list.orderGoods[this.activeIndex].total_pay_price=(this.accMul(list.orderGoods[this.activeIndex].goods_price , list.orderGoods[this.activeIndex].total_num)).toFixed(2); //打折后的价格
    list.orderGoods[this.activeIndex].total_price=(this.accMul(list.orderGoods[this.activeIndex].goods_price , list.orderGoods[this.activeIndex].total_num)).toFixed(2); //原价格
    console.log(JSON.stringify(list.orderGoods[this.activeIndex]))
   },
   cutButton(){//减按钮
    const list=  this.orderList &&  this.orderList;
    // console.log(JSON.stringify(list.orderGoods[this.activeIndex]))
    if(list.orderGoods[this.activeIndex].total_num>1){
      list.orderGoods[this.activeIndex].total_num --;//修改菜品数量
      list.orderGoods[this.activeIndex].total_num=(list.orderGoods[this.activeIndex].total_num).toFixed(2)
      list.orderGoods[this.activeIndex].total_pay_price=(this.accMul(list.orderGoods[this.activeIndex].goods_price , list.orderGoods[this.activeIndex].total_num)).toFixed(2); //打折后的价格
      list.orderGoods[this.activeIndex].total_price=(this.accMul(list.orderGoods[this.activeIndex].goods_price , list.orderGoods[this.activeIndex].total_num)).toFixed(2); //原价格

    }else if(list.orderGoods[this.activeIndex].total_num==1 || list.orderGoods[this.activeIndex].total_num<1){
      this.deleteButton()
    } 
     console.log(JSON.stringify(list.orderGoods[this.activeIndex]))
   },
   halfButton(){
     const list=  this.orderList &&  this.orderList;
     if(list.orderGoods.length>0 && (list.orderGoods[this.activeIndex].total_num<1 ||list.orderGoods[this.activeIndex].total_num==1)){
        list.orderGoods[this.activeIndex].total_num=0.5;
        list.orderGoods[this.activeIndex].total_pay_price=(this.accMul(list.orderGoods[this.activeIndex].goods_price ,0.5)).toFixed(2); //打折后的价格
     }else if(list.orderGoods.length>0 && list.orderGoods[this.activeIndex].total_num>1){
       this.showHalf=true;
       this.num=list.orderGoods[this.activeIndex].total_num
     }
   },
  getHalf(){  //确认提示改变数量
     const list=  this.orderList &&  this.orderList;
     if(list.orderGoods.length>0){
        list.orderGoods[this.activeIndex].total_num=0.5;
        list.orderGoods[this.activeIndex].total_pay_price=(this.accMul(list.orderGoods[this.activeIndex].goods_price ,0.5)).toFixed(2); //打折后的价格
     }
   },
  showConfirm(){ //结算按钮出现提示框
    const arr=this.orderList.orderGoods ? this.orderList.orderGoods :[];
    const res=arr.some(item=>{
    if(item.is_print=== '' ||  this.againSend===true){
      return true
    }
    })
    if(res){
      this.showAccHiModal=true;
    }else{
       this.showAccountModal=true;
    }
  },
  turnOnModal(data){
    this.showAccountModal=data;
  },
  async finishSend(number){ //点击完成送单
    if(this.disabledWanc){
      return
    }
      const chuanArr=this.orderList.orderGoods ? this.orderList.orderGoods:[];
      let checkData = await checkFoundingLogStatus({founding_log_id : this.$route.query.foundingLoId})
      let foundingLoId = checkData.data.data.founding_log_id
      const message={
            "founding_log_id": foundingLoId,
            "is_print": number,
            buyer_remark : this.remark,
            order:chuanArr
          }
        this.disabledWanc = true
        orderCreate(message)
              .then((res) => {
                if(res.data.status===200){
                  this.$message.success('送单成功', 1.5);
                  this.remark = ''
                  this.socketApi.websocketsend({
                    "key": "diancaiAction",
                    value:{
                      token: Cookies.get("Access-Token"),
                      founding_log_id:foundingLoId,
                      devicenums : res.data.data.deviceNumber
                    } 
                  }); 
                  this.orderDetail();
                  this.$router.push({path:"/yingye"}) 

                }else{
                  this.$router.push({path:"/yingye"})    
                }           
              })
              .finally(err => {
                  this.disabledWanc = false
              })
              setTimeout(() => {
                this.disabledWanc = false
              },8000)
      },
      updatedPrice(content){   //支付成功后更新相应的价钱
        if(content){
          this.orderDetail()
        }
      },
      keyUp(){
        const _this=this;
        document.onkeydown = function(e) {
              let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
              if (e1 && e1.keyCode == 118) { //点击F7先送
                _this.clickSend(1)
              } else if (e1 && e1.keyCode == 119) { //点击F8补单不打印
                _this.clickSend(2);
              } else if (e1 && e1.keyCode == 116) {//点击F5结算
                _this.showConfirm();
                  el.preventDefault();
              } else if (e1 && e1.keyCode == 112) {//点击F1完成送单
                _this.finishSend(1)
              }else if(e1 && e1.keyCode == 27){
                _this.$router.go(-1);
              }
          }
      },
      handleRemark(val){
        this.remark = val
        this.showRemark = false
      }
    },
    created(){
     this.keyUp()
    },
    mounted() {
      this.orderDetail();
    },
    updated(){
      // alert(document.onkeydown +'2222222')
    },
    destroyed(){
       clearTimeout(this.timer);
    }
    // updated(){
    //    const _this=this;
    //    document.onkeydown = function(e) {
    //         let e1 = e || event || window.event || arguments.callee.caller.arguments[0]
    //         if (e1 && e1.keyCode == 118) { //点击F7先送
    //           _this.clickSend(1)
    //         } else if (e1 && e1.keyCode == 119) { //点击F8补单不打印
    //            _this.clickSend(2);
    //         } else if (e1 && e1.keyCode == 116) {//点击F5结算
    //            _this.showConfirm();
    //             // el.preventDefault();
    //         } else if (e1 && e1.keyCode == 112) {//点击F1完成送单
    //            _this.finishSend(1)
    //         }
    //     }
     
    // }
  };
</script>
<style lang="less" scoped>
html,
body,
#app {
  height: 100vh;
}
.opentable {
  width: 100%;
  height: 87.8vh;
  background: #b4b4b6;
  .fistRow{
    background: #333333;
    height:7vh;
    line-height: 7vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
    display: flex;
    justify-content: space-between;
    .logo {
      // display: flex;
      // align-items: center;
       width:2.5vw;
      height:2.5vw;
      margin-left: 1vw;
      background-color: rgba(255, 103, 0, 1);
      margin-right:1.3vw;
      border-radius: 5px;
       padding: 0.2vh;
      display: flex;
      justify-content:center;
      align-items: center;
      img {
        //  height: 100%;
        width: 100%;
        padding-right: 2px;
      }
    }  
     .tableName{
         font-size: 1.8vh;
         font-weight: bold;
         color:white;
      }
    .close{
      width: 3vw;
      font-size: 1.7vw;
      height:3vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color:white;
      cursor: pointer;
      }
  }
  .secondRow{
    display: flex;
    height: 80.8vh;
    background-color: rgba(182, 182, 182, 1);
    .leftConent{
      width:24vw;
      height: 80.1vh;
      background: #e4e4e4;
      margin-top:0.5vh;
      position: relative;
      .orderList{
        background: #e4e4e4;
        height: 72.8vh;
        overflow-y:auto;
        overflow-x:hidden;
        cursor: pointer;
        font-size: 1.8vh;
        // padding-left: 3px;
        li{
          font-size: 1.8vh;
          width:25vw;
          height: 8vh;
          border-bottom: 1px solid white;
          padding-left:0.5vw;
          padding-right:1.5vw;
          .top{
            display: flex;
            justify-content: space-between;
            .name{
              line-height: 3vh;
              width:18vw;
              height: 3vh;
              overflow:hidden;
              white-space:nowrap;
              text-overflow:ellipsis;
            }
            .print{
              color:rgba(113, 162, 78, 1);
              float: left;
            }
          }
          
          .bottom{
            overflow: hidden;
            line-height: 5vh;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .spec_content{
              background: #d4d4d4;
              color: #ff6600;
              height: 2.3vh;
              line-height: 2.3vh;
              padding: 0 2px;
              font-size: 1.8vh;
              border-radius: 0.3vh;
            }
            .totalPrice{
              float: right;

            }
          }
        }
        .active{
          background-color: rgba(255, 102, 0, 1);
        }
        &::-webkit-scrollbar {
            /*滚动条整体样式*/
            width : 5px;  /*高宽分别对应横竖滚动条的尺寸*/
            height: 5px;
        }
        &::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            border-radius: 6px;
            background   : #636363;
        }
        &::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            border-radius: 6px;
            background   : #969595;
        }
      }
      .calculate{
        position: absolute;
        right: 0.5vw;
        bottom: 0.3vh;
        span{
          margin-left:0.5vw;
        }
        .red{
          color:rgba(255, 0, 0, 1);
        }
      }
    }
    .centContent{
      width:9vw;
      height: 80.1vh;
      background: rgba(51, 51, 51, 1);
       margin-top: 0.5vh;
      .type{     
        width:9vw;
        li{
          height: 6vh;
          width:9vw;
          border-bottom: 1px solid  rgba(114, 113, 113, 1);
          line-height: 6vh;
          font-size: 1.8vh;
          color: white;
          text-align: center;
          cursor: pointer;
        }
      }
    }
    .rightConent{
      width:67vw;
      height: 80.8vh;
      background: rgba(229, 229, 228, 1);
    }
  } 
}
   .bottom_b {
    color: white;
    display: flex;
    justify-content: space-between;
    background-color: rgba(51, 51, 51, 1);
    .other {
      margin-left: 1vw;
    }
    .other button {
      width: 12vw;
      height: 5.6vh;
      margin: 0.8vh 0 0.8vh 1.5vw;
      padding: 0.2vh 0.5vw 0.2vh 0.5vw;
      border-radius: 0.2vw;
      text-align: center;
      font-size: 2vh;

      color: black;
      font-weight: bold;
      background: #e4e4e4;
    }
    .time {
      width: 11vw;
      height: 5.6vh;
      line-height: 5.5vh;
      margin: 0.8vh 0 0.8vh 1.5vw;
      // padding: 0.2vh 0.5vw 0.2vh 0.5vw;
      border-radius: 0.2vw;
      text-align: center;
      font-size:2vh;
      color: #e4e4e4;
      background-color:#595758;
      // cursor: pointer;
      /deep/.ant-btn{
        width: 11vw;
        height: 5.6vh;
        line-height: 5.5vh;
        background-color:#595758;
        font-size:2vh;
       color: #e4e4e4;
       border-color:#595758 ;
       border-radius: 0.2vw;
      }
    }
     .gray{
        /deep/.ant-btn{
          background-color:#f5f5f5 !important;
          color:rgba(0,0,0,.25);
          border-color:#f5f5f5 !important;
        }

    }
    .account{
       /deep/.ant-btn{
          background-color:rgba(50, 147, 255, 1) ;
          border-color: rgba(50, 147, 255, 1) ;
       }
    }
    .finish{
      background-color:#ff6600;
    }
    .lock {
      display: flex;
    //  :hover{
    //   // background-color: #e4e4e4;
    //   // color: #333333;
    //   // cursor: pointer;
    //  }
    }
    .lockRight{
      display: flex;
      align-items: center;
       .input{
       display: flex;
      .search {
        display: flex;
        // width: 8vw;
        height: 5.6vh;
        line-height: 4vh;
        margin: 0.3vh 0 0 2vw;
        /deep/ .ant-input{
        height: 5.6vh;
        border: 0;
        border-radius: 0.4vh 0 0 0.4vh;
        border-right: 1px solid #dddddd;
        width: 10vw;
        }
      }
      .jianpan {
        width: 4vw;
        display: flex;
        height: 5.6vh;
        margin: 0.5vh 0 0 0;
        margin: 0.3vh 0 0 -1vw;
        background-color: rgba(240, 240, 240, 1);
        border-radius: 0.4vh;
        cursor: pointer;
        img {
          width: 3.6vw;
          padding-left: 1vw;
        }
      }
    }
    }  
  }
  .printback{
    background-color: #ffcc73;
  }
  .notprinted{
   color: rgba(212, 48, 48, 1) !important;
  }
 .leftLogo{
  display: flex;
  justify-content:center;
  align-items: center;
 }
 .mark{
   font-size: 2.8vh !important;
   font-weight: 900 !important;
 }
</style>
  