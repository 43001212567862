<template>
  <div>
    <a-modal
      :visible="isShow"
      title="折扣比例"
      cancel-text="取消"
      ok-text="确定"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div>
        <a-form-model 
          :model="form" 
          :rules="rules"
          ref="ruleForm"
          :label-col="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-form-model-item ref="price" label="折扣" prop="price">
            <a-input
              class="input"
              v-model="form.price"
              placeholder="请输入0-10之间的数字"
              @keyup.enter.native="handleOk"
            ></a-input>
          </a-form-model-item>
          <div class="ji">
             <keyNum :parentNum="this.form.price" @hidden_num="updateNum"/>
          </div>         
      </a-form-model> 
      </div>
    </a-modal>
  </div>
</template>
<script>
import keyNum from "@/components/keycode/num";
export default {
  props: ["isShow",],
  components: {keyNum},
  data() {
    return {
      labelCol: { span: 4},
      wrapperCol:{ span: 15},
      form: {
        price:''
      },
      rules: {
        price: [
          {required: true, trigger: "change" ,message: "请输入折扣",},
          // { pattern : /^(\d|10)(\.\d)?$/, message: '请输入0-10之间的数（最多保留一位小数）', trigger: 'change' }，
           { pattern : /10|[1-9]/, message: '请输入0-10之间的数（不包含0）', trigger: 'change' }
        ] 
      },   
    };
  },
  methods: {
    updateNum(num){
      this.form.price = num;
      
    }, 
    handleOk() {
      const _this=this;
      _this.$refs.ruleForm.validate(valid => {
        if (valid) {  
          
           _this.$emit('chooseData',_this.form.price,true);//向父组件传递选取的数据    
         
        } else {
          return false;
        }
      });
    },
    handleCancel() {  //点击取消按钮 
      this.$emit("cancel", false);
      this.form.price="";    
    },
  
  },   
};
</script>
<style lang="less" scoped>
/deep/.ant-modal-content{
    width: 35vw;
    background:rgba(237, 238, 242, 1);
}
/deep/.ant-form-item-label {
  font-size: 1.8vh;
  margin-top:2vh;
}
/deep/.ant-form-item-control{
  margin-top:2vh;
}
.ant-input {
  width: 18vw;
  border-color: #999999;
  background: #edeef2;
  height:6vh;
  font-size: 2.0vh;
}
  /deep/.ant-modal-footer {
  text-align: center;
  margin-top: 3vh;
}
/deep/.ant-btn {
  width: 13vh;
  height: 6vh;
  font-size: 2.0vh;
}
/deep/.ant-modal-footer button + button {
  width: 7vw;
  height: 5vh;
  font-size: 1.8vh;
  border-radius: 0.5vh;
  color: white;
  margin-left: 5vh;
  background: rgb(238, 121, 12);
  border-color: rgb(238, 121, 12);
  width:13vh;
  height:6vh;
  font-size: 2.0vh;
}
/deep/.ant-modal-body{
  font-size: 1.8vh;
  padding: 0 1vw;
}
/deep/.ant-modal-title{
  font-size: 2vh;
  font-weight: bold;
}
/deep/.ant-form label{
  margin-top: 2vh;
  font-size: 2vh;
  height:6vh;
  line-height: 6vh;
}
/deep/.ant-modal-header{
   background:rgba(237, 238, 242, 1);
}
/deep/.ulJian{
  border: 1px solid #999999;
  border-radius: 4px;
  li{
    width:33.3%;
    height: 7.5vh;
    line-height: 7.5vh;
    font-size: 2.2vh;
  }
}
/deep/.ji{
  padding:0 2vw;
}
</style>
