<template>
<div class="list">
  <ul class="tabList">
    <li class="tatal type" @click="clickTotalTab">全部</li>
    <li
      v-for="(item,index) in tabList" 
      :key="index"
      :class="[activeIndex === index ? 'active' : '', 'type']"
       @click="clickTab(index,item.category_id)"
     >
     {{item.name}}
     </li>
  </ul>
  <ul class="goodlist">
    <li
      v-for="(item,index) in goodsListArr.data" 
      :key="index +'ss'"
      @click="clickEveryGoods(item)"
      :class="[item.is_stockout===2 ? 'qing' : '', 'listEvery']"
    > 
      <div class="total">
        <div class="goodsname">
          {{item.goods_name}}
        </div>
        <div>￥{{item.goods_price}}</div>
      </div>
      <div class="replace-item" v-if="item.is_soldout===2">
        <div class="jiao"></div>
        <div class="desc">已售罄</div>
      </div> 
      <div class="replace-item" v-if="item.limited_stock_total !=0.00">
        <div class="jiao"></div>
        <div class="desc">限量沽清</div>
      </div>    
    </li>
    <li @click="clickPageUp" v-if="goodsListArr.data && goodsListArr.data.length>0">上一页</li>
    <li @click="clickPageNext" v-if="goodsListArr.data && goodsListArr.data.length>0">下一页</li>
  </ul>
   <div class="code"  v-if="isShow">
       <keyCode  :keyboards="keyboards" :onSubmit="goodsRequest" v-on:updatekey="GetKeyVal" :form="form" />     
  </div>
</div>
</template>
<script>
import {categoryList,goodsList} from '@/request/business.js';
import keyCode from "@/components/keycode/keycode";
import Bus from '@/request/bus.js';
export default {
  components: {
      keyCode  
  },
  props:['searchIds','isShow','keyboards','form'],
  data() {
    return {
      tabList:[],//tab数据
      activeIndex:'',//激活状态的index
      goodsListArr:"",//商品列表
      page:1,//第几页
      category_id:'',//商品id
      tabId:'', //商品分id
    };
  },
   mounted(){
    this.tabRequest();
    this.goodsRequest();
  }, 
  updated(){
    // console.log(JSON.stringify(this.goodsListArr))
  },
  computed: {
  },
  methods: {
      onSubmit(){
        this.goodsRequest(this.form.username);
      },
      GetKeyVal(val) {
        if (this.keyboards === "username") {
            // this.form.username = val;
            this.$emit('getNumber',val)
        }
    },
    tabRequest(){  //商品分类请求
      categoryList()
            .then((res) => {
              //console.log(res.data.status)
              if(res.data.status===200){
                const list =res.data.data.list && res.data.data.list;
                this.tabList=list;
                Bus.$emit('transferType',list)
               // console.log(JSON.stringify())            
              }           
            })
            .catch(result => {
              return false;
            })
    },
    clickTab(index,id){
      this.activeIndex=index;
      this.tabId=id;
       this.page=1;
      this.goodsRequest(this.form.username);
      //console.log(this.tabList)
    },
    goodsRequest(id){    //商品列表请求
      const message={
        page:this.page,
        category_id: parseInt(this.tabId) ?  parseInt(this.tabId): "",
        // category_id:  parseInt(id) ? parseInt(id): "",
        page_size:33,
        goods_no:'',
        goods_name:id
      }   
      goodsList(message)
            .then((res) => {
             // console.log(res.data.status)
              if(res.data.status===200){
                let list =res.data.data.list && res.data.data.list;
                list.data.forEach(item => {
                  if(item.spec_type == 20){
                    let price = 0
                    if(item.spec_content){
                        JSON.parse(item.spec_content).forEach((ele,i) => {
                        if(i == 0){
                          price = ele.spec_price
                        }else{
                          price = (price > ele.spec_price ? ele.spec_price : price)
                        }
                      })
                      item.goods_price = Number(price).toFixed(2)

                    }
                  }
                })
                //this.tabList=list;
                this.goodsListArr=list;
               // console.log(JSON.stringify(list))              
              }           
            })
            .catch(result => {
              return false;
            })
    },
    clickTotalTab(){ //点击全部菜单
      this.activeIndex="";
      this.tabId="";
      this.page=1;
      this.goodsRequest(this.tabId);
      //  this.goodsRequest(id);
    },
    clickPageNext(){     //点击下一页
      const lastPage=this.goodsListArr.last_page;
      const id=this.searchIds?this.searchIds:"";
      //console.log(this.searchId)
      if(this.page<lastPage){
         this.page++;
          this.goodsRequest(id)
      }else{
        this.page=lastPage;
         this.goodsRequest(id);
         this.$message.error(('这已经是最后一页了'),1.5);
      }      

    },
    clickPageUp(){  //点击上一页
    const id=this.searchIds?this.searchIds:"";
      if(this.page>1){
         this.page--;
          this.goodsRequest(id);
      }else if(this.page==1){
        this.$message.error(('这已经是最后一页了'),1.5);
      }
    },
    clickEveryGoods(item){  //点击每一个商品
      if(item&&item.is_soldout===1){
        this.$emit('goodsData',item)
      }
    
    }
  }, 
};
</script>
<style lang="less" scoped>
  .list{
    width:67vw;
    height: 80.8vh;  
    .tabList{
      display: flex;
      flex-wrap: wrap;
      padding-left: 0.5vw;
      width:67vw;
      margin-bottom:0vh;
      height: 10vh;
      .type{
        line-height: 4vh;
        height: 4vh;
        margin: 0.5vh 0.5vw 0.5vh 0.1vw;
        padding:0 0.3vw;
        border-radius: 0.2vw;
        list-style: none;
        background: rgba(114, 113, 113, 1);
        text-align: center;
        cursor: pointer;
        font-size:2vh;
        color:white;
        padding:0 1.5vw;
      }
      .tatal{
        background: rgba(255, 102, 0, 1);
        color:white;
      }
      .active{
        background: rgba(80, 80, 80, 1);
        color:rgba(255, 102, 0, 1);
      }
    }
    .goodlist{
      display: flex;
      flex-wrap: wrap;
      padding-left: 0.5vw;
      width:69vw;
      margin-top:1.1vh;
      position: relative;
       font-size: 1.8vh;
      li{
        font-weight: bold;
        color: #333333;
        font-size: 1.8vh;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 13vh;
        width: 9.0vw;
        background: #e4e4e4;
        margin-right: 0.7%;
        margin-bottom: 0.7%;
        border-radius: 5px;
        cursor: pointer;
        border: 1px solid #e4e4e4;  
        position: relative;
        text-overflow: ellipsis;
        // white-space: nowrap;
        overflow: hidden;
        word-break: break-all; 
        &:hover{
          background-color: #ff6600;
          border:1px solid #ff6600;
          color:white;
        }  
        .total{
          text-align: center;
          width:9.3vw;
          word-break: break-all;
        }
      }
    }
   }
    .replace-item {
    right: 0;
    top: 0;
    position: absolute;
    color: #ffffff;
    font-size: 12px;
    }
    .jiao, .desc {
    position: absolute;
    top:0;
    right: 0;
    }
  .jiao {
    width: 44px;
    height: 80px;
    transform: rotate(-50deg);
    -webkit-transform: rotate(-50deg);
    -moz-transform: rotate(-50deg);
    -ms-transform: rotate(-50deg);;
    top: -30px;
    right: -10px;
    background-color: #ff685d;
    padding:0;
  }
  .desc {
    transform: rotate(40deg);
    top: 8px;
    font-size: 12px;;
    white-space: nowrap;
  }
  .qing{
    background: #a3a2a1 !important;
    border:1px solid #a3a2a1 !important;
    color:white;
  }
  .code{
   position: fixed;
   right: 4vw;
   bottom:8vh;
   background: #f2f2f2;
   padding:1vw 1vw;
   padding-right: 0.5vw;
  border-radius: 4px;
 }
 
</style>
