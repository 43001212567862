<template>
  <div>
    <a-modal
      :visible="isShow"
      title="余额结算"
      cancel-text="取消"
      ok-text="下一步"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="myMonerhead">
        <h3 style=" ">
          应收金额 :&nbsp; {{ying}}元
        </h3>
        <h3 class="Span">待付金额 :&nbsp; {{sheng}}元</h3>
         <h3 style=" ">
          支付金额 :&nbsp; {{form.price?form.price:0.00}}元
        </h3>
        <h3 style=" " class="orange">
          会员余额 :&nbsp; {{user?user.balance:0.00}}元
        </h3>
      </div>
      <div>
        <a-form-model 
          :model="form" 
          :rules="rules"
          ref="ruleForm"
          :label-col="{ span: 4 }" 
          :wrapper-col="{ span: 10 }"
        >
          <a-form-model-item ref="price" label="金额" prop="price">
            <a-input
              class="input"
              v-model="form.price"
            ></a-input>
          </a-form-model-item>
          <div class="ji">
             <keyNum :parentNum="this.form.price" @hidden_num="updateNum"/>
          </div>         
      </a-form-model> 
      </div>
    </a-modal>
  </div>
</template>
<script>
import keyNum from "@/components/keycode/num";
import {orderpaystatus,orderpay} from '@/request/business.js';
export default {
  props: ["isShow",'ying','sheng','user','type','userId'],
  components: {keyNum},
  data() {
    return {
       form: {
          price:this.sheng
        },
        rules: {
          price: [
            {required: true,  trigger: "blur" ,message: "请输大于0的数字",},
          ] 
        },   
    };
  },
   watch:{
      sheng:{
        handler(){
          this.form.price=this.sheng
        },
        deep:true,
    }
  },
  methods: {
    updateNum(num){
      this.form.price = num;
    }, 
    handleOk() {
      const _this=this;
      _this.$refs.ruleForm.validate(valid => {
        if (valid) { 
          _this.payRequest()         
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      //点击取消按钮
      this.$emit("cancel", false);
    },
     payRequest(){ //请求支付
      const _this=this; 
      const foundingLoId=this.$route.query.foundingLoId && this.$route.query.foundingLoId;
      const message={
         user_id:_this.userId,//会员卡必填
        foundingLoId:foundingLoId, //当前桌台ID
        pay_type:this.type,  //付款方式
        auth_no:'', //付款码  支付宝微信必填
        current_price:_this.form.price,  //付款金额     
      } 
       orderpay(message)
            .then((res) => {
              if(res.data.status===200){
                const paylog_id=res.data.data.paylog_id  && res.data.data.paylog_id;
                //_this.paylog_id=paylog_id;
                _this.$emit('cancel',false)//关闭按钮
               _this.orderStatusRequest(paylog_id);//请求获取支付状态              
              }else if(res.data.status===500){
                  //  _this.$message.warn('支付总金额大于订单金额', 1.5);//显示更新成功
              }                  
            })
            .catch(result => {
              return false;
          })
    },
    orderStatusRequest(id){   //余额结算获取支付状态
      const _this=this;
      orderpaystatus({paylog_id:id})
        .then((res) => {
           console.log(res.data.status)
          if(res.data.status===200){
          const listItem=res.data.data.status  && res.data.data.status; 
          //  _this.icon= res.data.data.status.status  &&    res.data.data.status.status;
          // console.log(res.data.data.status.status)      
        //  _this.da=_this.addNumber(_this.da);
        //  _this.addNumber(_this.da)
        //  this.$emit('changePrice',true)
        _this.$emit('chooseData',_this.form.price,listItem,'vip支付',true);//向父组件传递选取的数据         
          }                   
          })
        .catch(result => {
            return false;
          })
    }
  },
    
};
</script>

<style lang="less" scoped>
.myMonerhead {
  width: 100%;
  border-radius: 0.5vh;
  background-color: #c9caca;
  font-size: 1.8vh;
  display: flex;
  flex-wrap: wrap;
  padding:1vh 1vw;
}
h3{
font-size: 1.8vh !important;
width:50%;
margin-bottom: 0;
line-height: 3.5vh;
}
.orange{
  color:rgba(255, 130, 47, 1);
}
.Span {
  font-size: 1.8vh;
}

.ji{
  padding:0 2vw;
}
/deep/.ant-modal-content{
    width: 35vw;
    background:rgba(237, 238, 242, 1);
}
/deep/.ant-form-item-label {
  margin-top: 3vh;
  font-size: 1.8vh;
}
/deep/.ant-form-item-control {
   margin-top: 3vh;
}
.ant-input {
  width: 18vw;
  height: 4vh;
}
  /deep/.ant-modal-footer {
  text-align: center;
  margin-top: 3vh;
}
/deep/.ant-btn {
  width: 7vw;
  height: 5vh;
  font-size: 1.8vh;
}
/deep/.ant-modal-footer button + button {
  width: 7vw;
  height: 5vh;
  font-size: 1.8vh;
  border-radius: 0.5vh;
  color: white;
  margin-left: 5vh;
  background: rgb(238, 121, 12);
  border-color: rgb(238, 121, 12);
}
/deep/.ant-modal-body{
  font-size: 1.8vh;
  padding-bottom: 0;
}
/deep/.ant-modal-title{
   font-size: 1.8vh;
}
/deep/.ant-form label{
  font-size: 1.8vh;
}
/deep/.ant-modal-header{
   background:rgba(237, 238, 242, 1);
}


</style>