<template>
  <div>
    <a-modal
      :visible="isShow"
      title="支付宝结算"
      cancel-text="取消"
      ok-text="确定"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="myMonerhead">
        <h3 style=" ">
          应收金额 :&nbsp; {{ying}}元
        </h3>
        <h3 class="Span">待付金额 :&nbsp; {{sheng}}元</h3>
         <h3 style=" ">
          支付金额 :&nbsp; {{wPrice}}元
        </h3>
      </div>
      <div class="input">
        <a-form-model 
          :model="form" 
          :rules="rules"
          ref="ruleForm"
        >
          <a-form-model-item ref="price" prop="price"  label="">
            <a-input
              class="input"
              v-model="form.price"
              ref="input" 
              placeholder="请扫描付款码"
            ></a-input>
          </a-form-model-item>          
      </a-form-model> 
      </div>
      <a-result title="支付成功!" v-if="icon === 2">
        <template #icon>
          <a-icon type="smile" theme="outlined" :style="{ color: 'rgba(40, 172, 60, 1)' }" />
        </template>
      </a-result>
      <a-result title="支付失败!" v-if="icon === 3">
        <template #icon >
          <a-icon type="frown" theme="outlined" :style="{ color: 'rgba(40, 172, 60, 1)' }" />
        </template>
      </a-result>
      <div class="loading" v-if="icon === 1">
         <Loading />
      </div>    
    </a-modal>
  </div>
</template>
<script>
import keyNum from "@/components/keycode/num";
import {orderpaystatus,orderpay} from '@/request/business.js';
import Loading from '@/components/payLoading/payLoading';
export default {
  props: ['wPrice','type','isShow','ying','sheng'],
  components: {keyNum,Loading},
  data() {
    return {
       form: {
          price:''
        },
        rules: {   //付款码
          price: [
            {required: true,  trigger: "blur" ,message: "请输大于0的数字",},
          ] 
        }, 
        icon:0,//显示的支付状态 
        paylog_id:'',
        time:'',//接收定时器
    };
  },
  watch:{
    icon:{
      handler(){
        const _this=this;    
            if(_this.icon === 1){
              this.time=setInterval(() => {
                  setTimeout( _this.orderStatusRequest(_this.paylog_id), 0)           
              }, 1000)
           }else{
              clearInterval(this.time);
           };
           if(this.icon===2 || this.icon===3){
              clearTimeout(this.timeouts)
              this.timeouts = setTimeout(() => {
              _this.$emit("cancel", false);
            }, 1000)
           };
      },
      deep:true
    },
    form:{
      handler(){
         clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
            this.payRequest()
        }, 500)
      },
      deep:true,
    }
  },
 
  updated(){
  },
  methods: {
     addNumber(arr){  //添加表格序号
      if(arr){
        let i =1
        arr.map(item=>{       
          item.number = i;
           i++;
        })   
          return arr
      }
    },
    updateNum(num){
      this.form.price = num;
    }, 
    handleOk() {  
      if(this.icon !== 1){
         this.$emit('cancel',false)//关闭按钮  
      }   
    },
    handleCancel() {    //点击取消按钮                   
      if(this.icon !== 1){
         this.$emit('cancel',false)//关闭按钮  
      }
    },
    payRequest(){ //请求支付
      const _this=this; 
      const foundingLoId=this.$route.query.foundingLoId && this.$route.query.foundingLoId;
      const message={
        foundingLoId:foundingLoId,
        pay_type:this.type,  //付款方式
        auth_no:this.form.price,
        current_price:_this.wPrice,  //付款金额
      }  
       orderpay(message)
            .then((res) => {
              if(res.data.status===200){
                const paylog_id=res.data.data.paylog_id  && res.data.data.paylog_id;
                _this.paylog_id=paylog_id;
               _this.orderStatusRequest(paylog_id);//请求获取支付状态              
              }else if(res.data.status===500){
                   _this.$message.warn('支付总金额大于订单金额', 1.5);//显示更新成功
              }                  
            })
            .catch(result => {
              return false;
          })
    },
    orderStatusRequest(id){
      const _this=this;
      orderpaystatus({paylog_id:id})
        .then((res) => {
           console.log(res.data.status)
          if(res.data.status===200){
          const listItem=res.data.data.status  && res.data.data.status; 
           _this.icon= res.data.data.status.status  &&    res.data.data.status.status;
          console.log(res.data.data.status.status)      
        //  _this.da=_this.addNumber(_this.da);
        //  _this.addNumber(_this.da)
         _this.$emit('chooseData',true)         
          }                   
          })
        .catch(result => {
            return false;
          })
    }
  },   
};
</script>

<style lang="less" scoped>
.loading{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index:10;
}
.myMonerhead {
  width: 100%;
  border-radius: 0.5vh;
  background-color: #c9caca;
  font-size: 1.8vh;
  display: flex;
  flex-wrap: wrap;
  padding:1vh 1vw;
}
h3{
font-size: 1.8vh !important;
width:50%;
margin-bottom: 0;
line-height: 3.5vh;
}
.Span {
  font-size: 1.8vh;
}
.input{
  margin-top:3vh;
}
/deep/.ant-modal-content{
    width: 35vw;
    background: rgba(237, 238, 242, 1);
    position:relative;
}
/deep/.ant-form-item-label {
  margin-top: 3vh;
  font-size: 1.8vh;
}
/deep/.ant-input {
  height: 6vh;
}
 /deep/.ant-modal-header {
   background: rgba(237, 238, 242, 1);
}
  /deep/.ant-modal-footer {
  text-align: center;
  margin-top: 4vh;
  padding-bottom:3vh;
}
/deep/.ant-btn {
  width: 7vw;
  height: 5vh;
  font-size: 1.8vh;
}
/deep/.ant-modal-footer button + button {
  width: 7vw;
  height: 5vh;
  font-size: 1.8vh;
  border-radius: 0.5vh;
  color: white;
  margin-left: 5vh;
  background: rgb(238, 121, 12);
  border-color: rgb(238, 121, 12);
}
/deep/.ant-modal-body{
  font-size: 1.8vh;
  padding-bottom: 0;
  height: 50vh;
}
/deep/.ant-modal-title{
   font-size: 1.8vh;
}
/deep/.ant-form label{
  font-size: 1.8vh;
}
/deep/.ant-input{
  font-size: 3vh;
  line-height:6vh ;
  text-align: center;
  // color:red !important;
}
/deep/.ant-modal-footer{
  text-align: center;
  display: none;
}
/deep/.ant-modal-header{
   background:rgba(237, 238, 242, 1);
}
</style>