<template>
  <div>
    <a-modal
      :visible="isShow"
      title="现金结算"
      cancel-text="取消"
      ok-text="确定"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="myMonerhead">
        <h3 style=" ">
          应收金额 :&nbsp; {{ying}}元
        </h3>
        <h3 class="Span">待付金额 :&nbsp; {{sheng}}元</h3>
         <h3 style=" ">
          支付金额 :&nbsp; {{wPrice}}元
        </h3>
      </div>
      <a-result title="支付成功!" v-if="status === 2">
        <template #icon>
          <a-icon type="smile" theme="outlined" :style="{ color: 'rgba(40, 172, 60, 1)' }"/>
        </template>
      </a-result>
      <a-result title="支付失败!" v-if="status === 3" >
        <template #icon >
          <a-icon type="frown" theme="outlined" :style="{ color: 'rgba(40, 172, 60, 1)' }"/>
        </template>
      </a-result>
      <div class="loading" v-if="status === 1">
         <Loading />
      </div>
    </a-modal>
  </div>
</template>
<script>
import Loading from '@/components/payLoading/payLoading';
export default {
  
  props: ['wPrice','isShow','ying','sheng','status','user',],
  components: {Loading},
  data() {
    return {
       timeout:'',
    };
  },
  watch:{
    status:{
      handler(){   
        if(this.status===2 || this.status===3){
          console.log(this.status)
            clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
             this.$emit("cancel", false);
          }, 1000)
        }
      },
      deep:true,
    },
    isShow:{
      handler(){
        console.log(this.status)
        if(this.status===2 || this.status===3){
            clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
             this.$emit("cancel", false);
          }, 1000)
          
        }
      },
      deep:true,
    },

  },
  updated(){   
  },
  mounted(){

  },
  methods: { 
    handleOk() {   
      if(this.status===2 || this.status===3){
         this.$emit("cancel", false); 
      }
    },
    handleCancel() {
      //点击取消按钮
      if(this.status===2 || this.status===3){
         this.$emit("cancel", false);
      }
    },   
  },    
};
</script>
<style lang="less" scoped>
.loading{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index:10;
}
.myMonerhead {
  width: 100%;
  border-radius: 0.5vh;
  background-color: #c9caca;
  font-size: 1.8vh;
  display: flex;
  flex-wrap: wrap;
  padding:1vh 1vw;
}
h3{
font-size: 1.8vh !important;
width:50%;
margin-bottom: 0;
line-height: 3.5vh;
}
.Span {
  font-size: 1.8vh;
}
.input{
  margin-top:3vh;
}
/deep/.ant-modal-content{
    width: 35vw;
    background: rgba(237, 238, 242, 1);
    position:relative;
}
/deep/.ant-form-item-label {
  margin-top: 3vh;
  font-size: 1.8vh;
}
 /deep/.ant-modal-header {
   background: rgba(237, 238, 242, 1);
}
  /deep/.ant-modal-footer {
  text-align: center;
  margin-top: 4vh;
  padding-bottom:3vh;
}
/deep/.ant-btn {
  width: 7vw;
  height: 5vh;
  font-size: 1.8vh;
}
/deep/.ant-modal-footer button + button {
  width: 7vw;
  height: 5vh;
  font-size: 1.8vh;
  border-radius: 0.5vh;
  color: white;
  margin-left: 5vh;
  background: rgb(238, 121, 12);
  border-color: rgb(238, 121, 12);
}
/deep/.ant-modal-body{
  font-size: 1.8vh;
  padding-bottom: 0;
  height:50vh;
}
/deep/.ant-modal-title{
   font-size: 1.8vh;
}
/deep/.ant-modal-footer{
  text-align: center;
  display: none;
}

</style>