<template>
 <a-modal
  title="临时菜品"
  :visible="isShow"
  ok-text="确认" 
  cancel-text="取消"
  @ok="handleSubmit"
  @cancel="handleCancel" 
 >
  <div class="chooseModal">
     <a-form-model 
      :model="form" 
      :rules="rules"
      ref="ruleForm"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
     >
        <a-form-model-item label="菜品分类" prop="category_id">
          <!-- <a-select v-model="form.category_id" placeholder="请选择">
          <a-select-option :value="item.category_id" v-for="(item,index) in type" :key="index">
           {{item.name}}
          </a-select-option>         
          </a-select> -->
          <span class="lin">临时菜品</span>
        </a-form-model-item>
        <a-form-model-item ref="goods_name" label="菜品名称" prop="goods_name">
          <a-input
            class="input"
            v-model="form.goods_name"
          ></a-input>
        </a-form-model-item>
       <a-form-model-item ref="goods_price" label="价格" prop="goods_price">
          <a-input
            class="input"
            v-model="form.goods_price"
            @focus="focusPrice()"
            placeholder="请输入价格"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item ref="total_num" label="数量" prop="total_num">
          <a-input
            class="input"
            v-model="form.total_num"
            @focus="focusNum()"
             placeholder="请输入数量"
          ></a-input>
        </a-form-model-item>
        <!-- <a-form-model-item label="单位" prop="goods_unit">
          <a-select v-model="form.goods_unit" placeholder="请选择">
          <a-select-option :value="item.goods_unit_name" v-for="(item,index) in unitArr" :key="index">
           {{item.goods_unit_name && item.goods_unit_name }}
          </a-select-option>         
          </a-select>
        </a-form-model-item> -->
         <a-form-model-item label="打印机名称" :labelCol="labelCol" :wrapperCol="wrapperCol"  prop="printer_id">
                 <a-select  v-model="form.printer_id" placeholder="请选择">
                     <a-select-option
                        :value="item.printer_id"
                        v-for="(item,index) in printList"
                        :key="index"
                      >
                       {{item.printer_name}}
                      </a-select-option>
                     
                 </a-select>
              </a-form-model-item>
              
     </a-form-model>
      <keyNum :parentNum="this.form.total_num" @hidden_num="updateNum" :parentPrice="this.form.goods_price" @hidden_pr="updatePr" :focusType="focus" ref="son"/>  
</div>
 </a-modal>
</template>
<script>
import keyNum from "./compute.vue";
import Bus from '@/request/bus.js';
import {units,create} from '@/request/business.js';
import { list} from "@/request/api";
export default {
  props:['data','isShow'],
  components: {
    keyNum
  },
  data() {
    //  let validatorPhone=(rule,value,callback)=>{
    //    if(!value){
    //      callback(new Error('请输入数字'));
    //    }else if(parseInt(value)>parseInt(this.data.total_num)){
    //        callback(new Error('退菜数量不能超过已点数量'));
         
    //    }else if(parseInt(value) < parseInt(this.data.total_num) || parseInt(value) === parseInt(this.data.total_num) ){
    //      callback()
    //    }   
    // };
    return {
        form: {       
          // category_id:undefined,
          goods_name:'',
          goods_price:'',
          total_num:"1",
          // goods_unit:undefined,
          printer_id:undefined,
        },
        goods_unit:'',
        rules: {
          total_num: [
            { required: true,  trigger: "change" ,message: "请输大于0的整数",},
            { pattern:  /^\+?[1-9][0-9]*$/, message: "请输大于0的整数", trigger: "change" },
          ],
          // category_id:[{required: true,message: "请选择菜品分类",trigger: 'change'}],
          goods_name:[{required: true,message: "请填写菜品名称",}],
          goods_price:[{required: true,message: "请输入价格",}],
          // goods_unit:[{required: true,message: "请选择单位",}],
          printer_id:[{required: true,message: "请选择打印机",trigger: 'change'}],
      },
      type:[],//商品分类
      labelCol: { span: 10 },
      wrapperCol: { span: 9 },
      // unitArr:[],//单位数组
      focus:"price",
      focusNumber:'',
      printList:[],//打印机列表 
    };
  },
   watch:{
    data:{
      handler(){
          this.goods_name=this.data ? this.data.goods_name :'';
          this.goods_unit=this.data?  this.data.goods_unit:'';
      },
      deep:true
    },
    isShow:{
      handler(){
         if(this.isShow){
            this.printListRequest()
         }
      },
      deep:true,

    },
  },
   mounted(){ 
      Bus.$on("transferType", (val) => {    //取  Bus.$on
      this.type=val;
    });
    // this.unitsRequest()  
  },
  updated(){
  },
  methods: {
     updateNum(num){
      //  console.log(num)
      //  if(this.focus==='num'){
      //      this.form.total_num = num;
      //       this.focusNumber=this.form.total_num
      //  }else if(this.focus==='price'){
      //     this.form.goods_price = num;
      //      this.focusNumber=this.form.goods_price
      //  }  
        this.form.total_num = num;   
    },
    updatePr(pr){
       this.form.goods_price = pr;

    },
    handleSubmit(){   //点击确定按钮 
    const _this=this;
     this.$refs.ruleForm.validate(valid => {
       if (valid) {
        _this.createRequest();
        _this.form={       
          // category_id:undefined,
          goods_name:'',
          goods_price:'',
          total_num:"",
          // goods_unit:undefined,
        },
        this.$refs.son.num = '';
        this.$refs.son.price = '';
        this.focus="price";
       
        } else {       
          return false;
        }
     })
    },
    handleCancel(){ //点击取消按钮
    this.$emit('cancel',false);
    this.form={       
          // category_id:undefined,
          goods_name:'',
          goods_price:'',
          total_num:"",
          // goods_unit:undefined,
        },
    this.$refs.son.num = '';
    this.$refs.son.price = '';
    this.focus="price";    
    },
    // unitsRequest(){  //获取单位接口请求
    //    units()
    //         .then((res) => {       
    //           if(res.data.status===200){
    //             const list =res.data.data.list && res.data.data.list;
    //             this.unitArr=list;         
    //           }           
    //         })
    //         .catch(result => {
    //           return false;
    //         })
    // },
    createRequest(){
      const _this=this;
       create(this.form)
            .then((res) => {       
              if(res.data.status===200){
                const data=res.data.data;
                console.log(JSON.stringify(data))
                _this.$emit('chooseData',true,data);
                 _this.form={       
                  // category_id:undefined,
                  goods_name:'',
                  goods_price:'',
                  total_num:"",
                  goods_unit:undefined,
               }
                _this.$emit('cancel',false)//关闭按钮                    
              }           
            })
            .catch(result => {
              return false;
            })
    },
    focusPrice(){
      this.focus= 'price'; 
    },
    focusNum(){
      this.focus = 'num';     
    },
    printListRequest(){
      list({page:0, status:0}).then((response)=>{
         const oldList = response.data.data.list.data;
         this.printList=oldList;
      })
    }
  }, 
};
</script>
<style lang="less" scoped>
/deep/.ant-modal{
  width:43vw !important;  
}
/deep/.ant-modal-content,/deep/.ant-modal-header{
   background-color: rgba(237, 238, 242, 1);
}
/deep/.ant-modal-body{
  padding:1vh 1vw;
}
/deep/.ant-modal-footer{
  text-align: center;
}
/deep/.ant-btn-primary{
   border-color: rgba(255, 102, 0, 1);
   background-color: rgba(255, 102, 0, 1);
}
/deep/.ant-btn {
  width: 8vw;
  height: 4.6vh;
  font-size: 1.8vh;
  margin-right: 2vw;
  margin-left: 1vw;
}
/deep/.ant-modal-footer button + button {
  width: 8vw;
  height: 4.6vh;
  font-size: 1.8vh;
  border-radius: 0.5vh;
  color: white;
  margin-left: 5vh;
  background: rgb(238, 121, 12);
  border-color: rgb(238, 121, 12);
}
/deep/.ant-modal-title{
  font-size: 1.8vh;
}
/deep/.ant-form-item-required::before{
  color:rgba(255, 102, 0, 1);
}
/deep/.ant-form-item{
  display: flex;
}
/deep/.ant-form-item-control {
  width:12vw;
}
/deep/.ant-form-item label {
  font-size:1.8vh;
}
.chooseModal{
 display: flex;
 justify-content: space-between;
}
/deep/.ant-btn{
  font-size: 1.8vh;
}
.lin{
  display: inline-block;
  width: 12vw;
  padding-left: 11px;
  background: rgba(236, 236, 236, 1);
  height: 32px;
  line-height: 32px;
  border:1px solid #d9d9d9;
  border-radius: 4px;
}
  
</style>
