import { render, staticRenderFns } from "./openTable.vue?vue&type=template&id=35294bbb&scoped=true&"
import script from "./openTable.vue?vue&type=script&lang=js&"
export * from "./openTable.vue?vue&type=script&lang=js&"
import style0 from "./openTable.vue?vue&type=style&index=0&id=35294bbb&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35294bbb",
  null
  
)

export default component.exports