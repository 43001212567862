<template>
  <div>
    <a-modal
      :visible="isShow"
      :title="title"
      cancel-text="取消"
      ok-text="确认"
      @ok="clickPay"
      @cancel="closeModal"
    >
    <div class="ant-modal-titles">会员消费-待收金额<span style="color:#ff6600">{{sheng}}</span>元</div>
     <div class="content">
        <div class="left">
            <div class="hang">
              <a-input-search placeholder="请输入会员卡号/手机号" enter-button="查询" v-model="keywords" @search="chooseVip"/>
            </div>
            <ul>
              <li>姓名:&nbsp;{{detail&&detail.nick_name }}</li>
              <li>等级:&nbsp;{{grade}}</li>
            </ul>
            <div>手机:&nbsp;{{detail&&detail.mobile }}</div>
            <div>卡号:&nbsp;{{detail&&detail.card_number }}</div>
            <div>余额:&nbsp;{{(detail&&detail.balance) }} <span class="chong" @click="chongVip" v-if="detail && detail.user_id !==0">充值</span></div>
            <div>权益:&nbsp;{{discount}}<span v-if="discount">折</span></div>
        </div>
        <div class="right">
          <a-form-model 
            :model="form" 
            :rules="rules"
            ref="ruleForm"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item ref="price" prop="price"  label="付款金额">
              <a-input
                class="input"
                v-model="form.price"
                ref="input" 
              />
            </a-form-model-item>          
        </a-form-model>
        <div class="ji">
             <keyNum :parentNum="form.price"  @hidden_num="updateNum"/>
        </div> 

        </div>
     </div>
      <Recharge :isShow="showRecharge" :fData="detail" @changeModal="changeRechargeModal" @updateList="updatedListRe"/>
    </a-modal>
  </div>
</template>
<script>
import Recharge from "@/views/mis/modules/Recharge.vue";
import keyNum from "@/components/keycode/num.vue";
import { search } from "@/request/mis";
import {orderpaystatus,orderpay,orderUser} from '@/request/business.js';
export default {
  props: ['wPrice','isShow','ying','sheng','status','user','type','c'],
  components: {keyNum,Recharge},
  data() {
    return {  
       form: {
          price:this.sheng
        },
        rules: {
          price: [
            {required: true,  trigger: "change" ,message: "请输金额",},
          ] 
        }, 
        labelCol: { span: 7 },
        wrapperCol: { span: 14 }, 
        keywords:'',//查找会员信息 
        detail:this.user.length==0?"":this.user,//会员详情
        title:"",
        grade:'',//会员等级
        discount:'',//会员权益
        showRecharge:false,
        current_price:"",//会员扣除的金额
    };
  },
  watch:{
      sheng:{
        handler(){
          this.form.price=this.sheng;
        },
        deep:true,
    },
    isShow:{
       handler(){
            const local=localStorage.getItem('keywords') && localStorage.getItem('keywords');
            if(local && this.user.user_id !==0){
               this.keywords= JSON.parse(local).name && JSON.parse(local).name
            //   if(this.user && (JSON.parse(local).id === this.user.user_id)){
            //    // 获取input 框会员卡号或者手机号初始化信息
            //     // this.user_id=JSON.parse(local).id && JSON.parse(local).id;
            //   }
            }
            this.grade=this.detail.grade?this.detail.grade.name:'';
            this.discount=this.detail.grade?this.detail.grade.equity.discount:'';
          
        },
        deep:true,
    },
    user:{
      handler(){
        if(this.user.user_id==0){
           this.detail="";
           this.keywords="";
        }
         this.detail=this.user.length==0?"":this.user;
      },
      deep:true,
    },
    form:{
      handler(){
        this.$set(this.form , 'price' , this.getTofixed(this.form.price))
      },
      deep:true,
    }, 
  },
  mounted(){  
  },
  updated(){
    console.log(JSON.stringify(this.user))
  },
  methods: { 
    changeRechargeModal(){
      this.showRecharge=false;
    }, 
    updatedListRe(){
      this.$emit('changeYv',true);
    },
    chongVip(){
      this.showRecharge=true;
    },
    updateNum(num){
      this.form.price = num;
    }, 
    closeModal() {
      //点击取消按钮
      this.$emit("cancel", false);
    },
    clickPay(){ //会员余额支付按钮
       this.$refs.ruleForm.validate(valid => {
         if(valid){
          const local=localStorage.getItem('keywords') && localStorage.getItem('keywords');
          if(this.user.user_id !==0){
            this.payRequest();
          }else{
            this.$message.warn('请查询会员',1.5)
          }         
         }
       })
    },
    payRequest(){ //请求支付
      const _this=this; 
      const foundingLoId=this.$route.query.foundingLoId && this.$route.query.foundingLoId;
      const message={
        user_id:_this.detail && _this.detail.user_id,//会员卡必填
        foundingLoId:foundingLoId, //当前桌台ID
        pay_type:this.type,  //付款方式
        auth_no:'', //付款码  支付宝微信必填
        current_price:_this.form.price,  //付款金额     
      } 
       orderpay(message)
            .then((res) => {
              if(res.data.status===200){
                const paylog_id=res.data.data.paylog_id  && res.data.data.paylog_id;
                this.current_price=res.data.data.current_price  && res.data.data.current_price;
                _this.$emit('cancel',false)//关闭按钮
               _this.orderStatusRequest(paylog_id);//请求获取支付状态             
              }                
            })
            .catch(result => {
              return false;
          })
    },
    orderStatusRequest(id){   //余额结算获取支付状态
      const _this=this;
      orderpaystatus({paylog_id:id})
        .then((res) => {
           console.log(res.data.status)
          if(res.data.status===200){
            const listItem=res.data.data.status  && res.data.data.status; 
            console.log(_this.form.price)
            _this.$emit('chooseData',_this.current_price,listItem,'成功收款',true);//向父组件传递选取的数据         
            //_this.$emit('chooseData',_this.form.price,listItem,'成功收款',true);//向父组件传递选取的数据         
          }                   
          })
        .catch(result => {
            return false;
          })
    },
    chooseVip(){ //点击选中会员列表
      if(this.keywords==""){
         this.$message.warn('请输入手机号/卡号')
      }else{
        this.searchUser(1)
      }
     
    },
     searchUser(number){   //查询会员 
      const _this=this;      
      const message= {
        founding_log_id:this.$route.query.foundingLoId && this.$route.query.foundingLoId,
        keywords:this.keywords,
        select_type:number
      }   
     orderUser(message)
        .then((res) => {
          if(res.data.status===200){
              this.detail=res.data.data;
              if(res.data.data&& res.data.data.user_id==0){
                this.$message.warn('查询的会员会员不存在，请重新输入',1.5)
              }
              this.grade=res.data.data.grade?res.data.data.grade.name:'';
              this.discount=res.data.data.grade?res.data.data.grade.equity.discount:'';
              const info = { name:this.keywords, id: this.detail.user_id };
              localStorage.setItem('keywords', JSON.stringify(info));
              this.$emit('changePrice',true)
            }else if(res.data.status===500){
            
            }                  
          })
        .catch(result => {
            return false;
          })
    },
  },    
};
</script>
<style lang="less" scoped>
.content{
  display: flex;
  width:100%;
  .left{
    width:50%;
    ul{
      display: flex;
      flex-wrap:wrap;
      margin-bottom: 0;
      margin-top:1vh;
      li{
        width:60%;
        line-height: 6vh;
        font-size:2.0vh;
      }
      li:nth-child(even){
        width:40% !important;
        font-size: 1.8vh;
      }
    }
    div{
      font-size: 1.8vh;
      line-height: 4.5vh;;
    }
    .hang{
          display: flex;
          padding-left:0.5vw;
         .ant-input-group-wrapper{
            width:18vw;
         }
           /deep/.ant-input-group{
              width:30vw; 
            .ant-input{
              font-size: 1.8vh;
              border:1px solid rgba(153, 153, 153, 1) ;
              height:4vh;
              background: #edeef2;
              height:6vh;
              border-right: 0;
          }
           }
        }
  }
   .right{
    width:50%;
    border-left: 1px solid #d0d0d0;
    padding-left:1vw;
  }
}
/deep/.ant-modal-content{
  width:70vw;
  background: rgba(237, 238, 242, 1);
}
/deep/.ant-modal {
 width:70vw !important;
}
.ji{
  padding:2vw 4vw 0 4vw;
}
/deep/.ant-form-item label{
  font-size: 2.2vh;
  font-weight: bold;
}
/deep/.ant-modal-header{
  background: rgba(237, 238, 242, 1);
}
/deep/.ant-input{
  font-size: 1.8vh;
  height:6vh;
  font-size: 2.3vh;
  background: rgba(237, 238, 242, 1);
}
.right{
  /deep/.ant-input{
    font-weight: bold;
  }
}
.hang {
  /deep/.ant-btn-primary{
    width:6vw;
    background-color:#2a82e4;
    border-color:#2a82e4;
  }
}
/deep/.ant-btn{
  font-size: 2.0vh;
  height:6vh;
}
/deep/.ant-modal-titles{
  font-size: 2.2vh;
  font-weight: bold;
  height: 6vh;
  line-height: 5vh;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 2vh;
}
/deep/.ant-modal-body{
  padding:2.0vh;
}
/deep/.anticon svg{
  font-size: 3.5vh;
  margin-top:2.3vh;
  color: #999999;
}
/deep/.ant-form-item-label{
  line-height:6vh;
}
/deep/.ant-form-item{
  margin-bottom: 0;
}
/deep/.ulJian{
  border: 1px solid #999999;
  border-radius: 4px;
  li{
    width:33.3%;
    height: 7.5vh;
    line-height: 7.5vh;
  }
}
/deep/.ant-modal-footer .ant-btn-primary{
  background: #ff6600;
  border:1px solid #ff6600;
  font-size: 2.0vh;
  width:16vh;
  margin-left:10vh;
  margin-right:4vh;
}
/deep/.ant-modal-footer .ant-btn{
  font-size: 2.0vh;
  width:16vh;
}
.chong{
  font-size: 1.5vh;
  display: inline-block;
  margin-left:1vw;
  padding:0 1vw;
  line-height:3vh;
  color:#2a82e4;
  border: 1px solid #a6a6a6;
  border-radius: 4px;
  cursor: pointer;
}
</style>