<template>
 <a-modal
  title="修改菜品数量"
  :visible="isShow"
  ok-text="确认" 
  cancel-text="取消"
  @ok="handleSubmit"
  @cancel="handleCancel" 
  
 >
  <div class="chooseModal"  @click="changeCount" >
     <a-form-model 
      :model="form" 
      :rules="rules"
      ref="ruleForm"
     >
       <a-form-model-item ref="total_num" label="请输入" prop="total_num">
          <a-input
            class="input"
            v-model="form.total_num"
            ref='id'
            @select="selectText()"
            @click="focusText"
            @change="focusText"
          ></a-input>
        </a-form-model-item>
      <keyNum :parentNum="this.form.total_num" @hidden_num="updateNum" :blur="blur"/> 
     </a-form-model> 
</div>
 </a-modal>
</template>
<script>
import keyNum from "@/components/keycode/num.vue";
export default {
  props:['data','isShow'],
  components: {
    keyNum
  },
  data() {
    //  let validatorPhone=(rule,value,callback)=>{
    //    if(!value){
    //      callback(new Error('请输入数字'));
    //    }else if(parseInt(value)>parseInt(this.data.total_num)){
    //        callback(new Error('退菜数量不能超过已点数量'));
         
    //    }else if(parseInt(value) < parseInt(this.data.total_num) || parseInt(value) === parseInt(this.data.total_num) ){
    //      callback()
    //    }
     
    // };
    return {
      yuan:'',
        form: {
          total_num:this.data.total_num?(this.data.total_num).toString():'1'
        },
        rules: {
        total_num: [
          { required: true,  trigger: "change" ,message: "请输大于0的数",},
          { pattern:  /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/, message: "请输大于0的数", trigger: "change" },
          { min: 1, max: 6, message: '请输入合适的数字', trigger: 'change' },
        ],
      }, 
      blur:true, 
    };
  },
   watch:{
    isShow:{
      handler(){
          if(this.isShow){
            this.form.total_num=this.data.total_num?(this.data.total_num).toString():'1';
             window.addEventListener("click", this.changeCount);
            this.$nextTick(() => {
              this.$refs.id.select();
            });
        }
      }
    },
    form:{
      handler(){
        this.blur=false;
        console.log(this.blur)
      },
      deep:true,
    }
  },
   mounted(){ 
  },
  updated(){
  },
  methods: {
   
    focusText(){
       this.blur=false;
       console.log( this.blur)
    },
    selectText(){
      this.blur=true;
      console.log( this.blur)
    },
    changeCount(){
      this.$nextTick(() => {
              // this.$refs.id.focus();
              this.$refs.id.select();
            });
    },
     updateNum(num){
       console.log(num)
      this.form.total_num = num;
    },
    handleSubmit(){   //点击确定按钮 
      const _this=this;
      _this.$refs.ruleForm.validate(valid => {
        if (valid) {
          _this.$emit('cancel',false)//关闭按钮
          _this.$emit('chooseData',_this.form.total_num);//向父组件传递选取的数据
          _this.form.total_num=""
        } else {
          return false;
        }
      });
    },
    handleCancel(){ //点击取消按钮
    this.$emit('cancel',false)
     this.form.total_num=""
    // this.form.resetFields(); //清除书写框
    }
  }, 
};
</script>
<style lang="less" scoped>
/deep/.ant-modal{
  width:27vw !important;  
}
/deep/.ant-modal-content,/deep/.ant-modal-header{
   background-color: rgba(237, 238, 242, 1);
}
/deep/.ant-modal-body{
  padding:1vh 1vw;
}
/deep/.ant-modal-footer{
  text-align: center;
}
/deep/.ant-btn-primary{
   border-color: rgba(255, 102, 0, 1);
   background-color: rgba(255, 102, 0, 1);
   font-size: 1.8vh;
}
/deep/.ant-btn {
  width: 8vw;
  height: 4.6vh;
  font-size: 1.8vh;
  // margin-right: 2vw;
  // margin-left: 1vw;
}
/deep/.ant-modal-footer button + button {
  width: 8vw;
  height: 4.6vh;
  font-size: 1.8vh;
  border-radius: 0.5vh;
  color: white;
  margin-left: 5vh;
  background: rgb(238, 121, 12);
  border-color: rgb(238, 121, 12);
}
/deep/.ant-modal-title{
  font-size: 1.8vh;
}
/deep/.ant-form-item-required::before{
  color:rgba(255, 102, 0, 1);
}
/deep/.ant-form-item{
  display: flex;
}
/deep/.ant-form-item-control {
  width:12vw;
}
/deep/.ant-form-item label {
  font-size:1.8vh;
}
.chooseModal{
  .title{
    font-size: 1.8vh;
    line-height: 5vh;
  }
}

  
</style>
