<template>
 <a-modal
  :title="data && data.goods_name"
  :visible="isShow"
  ok-text="确认" 
  cancel-text="取消"
  @ok="handleSubmit"
  @cancel="handleCancel" 
 >
  <div class="chooseModal">
    <div class="title">零售价:&nbsp;&nbsp;￥{{data && data.goods_price}}<span v-if="data && data.goods_unit">/千克</span></div>
     <div class="title count">小计:&nbsp;&nbsp;￥{{count}}</div>
     <a-form-model  
        :model="form"
        :rules="rules"
        ref="ruleForm"
        :label-col="{span: 4}"
        :wrapper-col="{span: 10}"
     >
        <a-form-model-item ref="weight" label="重量:" prop="weight">
          <a-input
            placeholder="请输入重量"
            suffix="| 千克"
            v-model="form.weight"
          />
        </a-form-model-item>
      <div class="ji">
          <keyNum :parentNum="this.form.weight" @hidden_num="updateNum"/>
      </div>  
     </a-form-model >  
</div>
 </a-modal>
</template>
<script>
import keyNum from "@/components/keycode/num";
export default {
  props:['data','isShow'],
  components: {keyNum},
  data() {
    return {
       form:{
          weight:""
        },
        rules:{
          weight:[
            { required: true, message: '请输入大于零的数', trigger: 'change' },
            { pattern : /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/, message: '请输入大于零的数'}
            ]
        }, 
        count:0.00
    };
  },
  watch:{
    form:{
      handler(){
        const goods_price= this.data ?this.data.goods_price:0.00;
        this.count=this.form.weight>0?this.accMul(this.form.weight,goods_price):0.00;
      },
      deep:true,
    },
  },
  mounted(){ 
  },
  updated(){
   
  },
  methods: {
    updateNum(num){
      this.form.weight = num;
    },
    handleSubmit(){   //点击确定按钮 
      const _this=this;
       _this.$refs.ruleForm.validate((valid ) => {
        if (valid) {        
          this.$emit('chooseData',this.form.weight);//向父组件传递选取的数据
          this.form.weight=''
          this.$emit('cancel',false)//关闭按钮  
        }
      })
    },
    handleCancel(){ //点击取消按钮
      this.form.weight=''
      this.$emit('cancel',false)
    },
    accMul(num1,num2){
      var m=0,s1=num1.toString(),s2=num2.toString(); 
      try{m+=s1.split(".")[1].length}catch(e){};
      try{m+=s2.split(".")[1].length}catch(e){};
      return Number(s1.replace(".",""))*Number(s2.replace(".",""))/Math.pow(10,m);
    }
  }, 
};
</script>
<style lang="less" scoped>
/deep/.ant-modal{
  width:33vw !important;  
}
/deep/.ant-modal-content,/deep/.ant-modal-header{
   background-color: rgba(237, 238, 242, 1);
}
/deep/.ant-modal-body{
  padding:1vh 2vw;
}
/deep/.ant-checkbox-wrapper{
  font-size: 1.8vh;
  background-color: white;
  height: 3.5vh;
  padding-left: 0.5vw;
  line-height: 3.5vh;
  border-radius: 4px;
  border:1px solid rgba(208, 208, 208, 1);
}
/deep/.ant-checkbox + span{
  padding-left:0.2vw;
  padding-right:1vw;
  padding-top:0.2vh;
}

/deep/.ant-modal-footer{
  text-align: center;
}
/deep/.ant-btn-primary{
   border-color: rgba(255, 102, 0, 1);
   background-color: rgba(255, 102, 0, 1);
}
/deep/.ant-btn {
  width: 8vw;
  height: 4.6vh;
  font-size: 1.8vh;
  margin-right: 2vw;
  margin-left: 1vw;
}
/deep/.ant-modal-footer button + button {
  width: 8vw;
  height: 4.6vh;
  font-size: 1.8vh;
  border-radius: 0.5vh;
  color: white;
  margin-left: 5vh;
  background: rgb(238, 121, 12);
  border-color: rgb(238, 121, 12);
}
/deep/.ant-modal-footer button + button{
  margin-left:2vw ;
}
/deep/.ant-modal-title{
  font-size: 2vh;
  font-weight: bold;
}
/deep/.ant-form-item-required::before{
  color:rgba(255, 102, 0, 1);
}
/deep/.ant-form-item label{
  font-size: 1.8vh;
  color:rgba(0, 0, 0, 0.65);
}
/deep/.ant-input{
  font-size: 1.8vh;
  width:16vw;
  height:5vh;
}
/deep/.ant-form-item{
  margin:1vh 0;
}
.chooseModal{
  .title{
    font-size: 1.8vh;
    line-height: 4vh;
  }
  .count{
    color:#ff3b30;
    font-weight: bold;
  }
}
/deep/.ant-input-suffix{
  color:rgba(166, 166, 166, 1);
  font-size: 1.8vh;
}
/deep/.ant-form-item-label{
  height:5vh;
  line-height:5vh ;
}
/deep/.ulJian{
   margin-top: 2vh;
  li{
    width:33.3%;
  }
} 
/deep/.ant-col-4{
  width: auto;

}
/deep/.ant-input-affix-wrapper{
  width:auto;
}
  
</style>
