<template>
  <div>
    <a-modal :visible="wasShow" title="提示" cancel-text="取消" ok-text="确定" @ok="handleOk">
      <p style="font-size: 1.6vh">尚未付清订单，不能结算此单!</p>
    </a-modal>
  </div>
</template>
<script>
export default {
  props: ["wasShow"],

  data() {
    return {
      wasShow: false,
    };
  },
  methods: {
    showModal() {
      this.wasShow = true;
    },
    handleOk(e) {
      console.log(e);
      this.wasShow = false;
    },
  },
};
</script>
 <style lang="less" scoped>
/deep/.ant-btn {
  width: 7vw;
  height: 5vh;
  font-size: 1.8vh;
}
/deep/.ant-modal-footer button + button {
  width: 7vw;
  height: 5vh;
  font-size: 1.8vh;
  border-radius: 0.5vh;
  color: white;
  margin-left: 5vh;
  background: rgb(238, 121, 12);
  border-color: rgb(238, 121, 12);
}
/deep/.ant-modal-footer {
  text-align: center;
  margin-top: 3vh;
}
</style>