<template>
  <div>
    <a-modal
      :visible="isShow"
      title="支付宝结算"
      cancel-text="取消"
      ok-text="下一步"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="myMonerhead">
        <h3 style=" ">
          应收金额 :&nbsp; {{ying}}元
        </h3>
        <h3 class="Span">待付金额 :&nbsp; {{sheng?sheng:0.00}}元</h3>
         <h3 style=" ">
          支付金额 :&nbsp; {{form.price?form.price:0.00}}元
        </h3>
      </div>
      <div>
        <a-form-model 
          :model="form" 
          :rules="rules"
          ref="ruleForm"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 10 }" 
        >
          <a-form-model-item ref="price" label="金额" prop="price">
            <a-input
              class="input"
              v-model="form.price"
            ></a-input>
          </a-form-model-item>
          <div class="ji">
             <keyNum :parentNum="this.form.price" @hidden_num="updateNum"/>
          </div>         
      </a-form-model> 
      </div>
    </a-modal>
  </div>
</template>
<script>
import keyNum from "@/components/keycode/num";
export default {
  props: ["isShow",'ying','sheng'],
  components: {keyNum},
  data() {
    return {
       form: {
          price:this.sheng
        },
        rules: {
          price: [
            {required: true,  trigger: "change" ,message: "请输大于0的数字",},
          ] 
        },   
    };
  },
   watch:{
      sheng:{
        handler(){
          this.form.price=this.sheng
        },
        deep:true,
    }
  },
  methods: {
    updateNum(num){
      this.form.price = num;
    }, 
    handleOk() {
      const _this=this;
      _this.$refs.ruleForm.validate(valid => {
        if (valid) {  
          // _this.orderRequest();   
          _this.$emit('chooseData',_this.form.price,'支付宝支付',true);//向父组件传递选取的数据         
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      //点击取消按钮
      this.$emit("cancel", false);
    },
  },   
};
</script>

<style lang="less" scoped>
.myMonerhead {
  width: 100%;
  border-radius: 0.5vh;
  background-color: #c9caca;
  font-size: 1.8vh;
  display: flex;
  flex-wrap: wrap;
  padding:1vh 1vw;
}
h3{
font-size: 1.8vh !important;
width:50%;
margin-bottom: 0;
line-height: 3.5vh;
}
.Span {
  font-size: 1.8vh;
}

.ji{
  padding:0 2vw;
}
/deep/.ant-modal-content{
    width: 35vw;
    background: rgba(237, 238, 242, 1);
}
/deep/.ant-form-item-label {
  margin-top: 2vh;
  font-size: 1.8vh;
}
/deep/.ant-form-item-control {
  margin-top: 2vh;
}
.ant-input {
  width: 18vw;
  height: 4vh;
}
  /deep/.ant-modal-footer {
  text-align: center;
  margin-top: 3vh;
}
/deep/.ant-btn {
  width: 7vw;
  height: 5vh;
  font-size: 1.8vh;
}
/deep/.ant-modal-footer button + button {
  width: 7vw;
  height: 5vh;
  font-size: 1.8vh;
  border-radius: 0.5vh;
  color: white;
  margin-left: 5vh;
  background: rgb(238, 121, 12);
  border-color: rgb(238, 121, 12);
}
/deep/.ant-modal-body{
  font-size: 1.8vh;
  padding-bottom: 0;
}
/deep/.ant-modal-title{
   font-size: 1.8vh;
}
/deep/.ant-form label{
  font-size: 1.8vh;
}
/deep/.ant-modal-header{
   background:rgba(237, 238, 242, 1);
}


</style>