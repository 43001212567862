import { render, staticRenderFns } from "./AlipayModal.vue?vue&type=template&id=fd68d0dc&scoped=true&"
import script from "./AlipayModal.vue?vue&type=script&lang=js&"
export * from "./AlipayModal.vue?vue&type=script&lang=js&"
import style0 from "./AlipayModal.vue?vue&type=style&index=0&id=fd68d0dc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd68d0dc",
  null
  
)

export default component.exports