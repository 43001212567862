<template>
 <a-modal
  title="明细"
  :visible="isShow"
  ok-text="确认" 
  cancel-text="取消"
  @cancel="handleCancel" 
 >  
  <div class="table">
    <a-table 
    :columns="columns" 
    :data-source="addNumber(data.orderGoods)" 
    bordered 
    :rowKey="(record,index)=>{return index}"
    @change="onChange"
    :pagination="{
        current:current,
     }"
    >
      <span slot="goods_name" slot-scope="text,record">
          {{text}}
          <span class="biao" v-if="record.taste">({{record.taste}}-</span>
          <span class="biao" v-if="record.practice">{{record.practice}}</span><span v-if="!record.avoid && (record.taste || record.practice)" class="biao">)</span>
          <span class="biao" v-if="record.avoid">-忌{{record.avoid}})</span>
      </span>
      <span slot="is_print" slot-scope="text,record">
          <span v-if="record.is_print===0">未打印</span>
          <span  v-if="record.is_print===1">已打印</span>
      </span>
       <span slot="total_num" slot-scope="text,record">
         {{text}}
          <span v-if="record.is_giving===2" class="biao">(赠)</span>
          <span v-if="record.is_foodback===2" class="biao">(退{{record.refund_total_num}})</span>
      </span>
    </a-table>
  </div>  
  <div class="record">共{{data.orderGoods&&data.orderGoods.length}}条记录</div>
 </a-modal>
</template>
<script>
export default {
  props:['data','isShow'],
  components: {  
  },
  data() {
    return {
      current: 1,
      columns:[
        {
          title: '顺序',
          dataIndex: 'number',
          key: 'number',
        },
        {
          title: '菜品名称',
          dataIndex: 'goods_name',
          key: 'goods_name',
          scopedSlots: { customRender: 'goods_name' },
        },
        {
          title: '规格',
          dataIndex: 'spec_content',
          key: 'spec_content',
          // scopedSlots: { customRender: 'goods_name' },
        },
        {
          title: '数量',
          dataIndex: 'total_num',
          key: 'total_num',
          scopedSlots: { customRender: 'total_num' },
        },
        {
          title: '单位',
          dataIndex: 'goods_unit',
          key: 'goods_unit',
        },
        {
          title: '单价',
          dataIndex: 'goods_price',
          key: 'goods_price',
        },
        {
          title: '应收',
          dataIndex: 'total_price',
          key: 'total_price',
        },
        {
          title: '实收',
          dataIndex: 'total_pay_price',
          key: 'total_pay_price',
        },
        {
          title: '状态',
          dataIndex: 'is_print',
          key: 'is_print',
          scopedSlots: { customRender: 'is_print' },
        },
         {
          title: '送单时间',
          dataIndex: 'create_time',
          key: 'create_time',
        },
         {
          title: '收银员',
          dataIndex: 'order_clerk_name',
          key: 'order_clerk_name',
        },
         {
          title: '店员',
          dataIndex: 'settlement_clerk_name',
          key: 'settlement_clerk_name',
        },
    ]    
    };
  },
   mounted(){ 
  },
  updated(){ 
  },
  methods: {
    onChange(e){
       this.current = e.current;
    }, 
    // handleSubmit(){   //点击确定按钮    
    //   this.$emit('cancel',false)//关闭按钮
    //   this.$emit('chooseData',false);//向父组件传递选取的数据
    // },
    handleCancel(){ //点击取消按钮
      this.$emit('cancel',false);
       this.current=1;
    },
    addNumber(arr){
      if(arr){
        let i =1
        arr.map(item=>{       
          item.number = i;
           i++;
        }) 
       // console.log(JSON.stringify(arr))        
          return arr
      }
    }

  }, 
};
</script>
<style lang="less" scoped>
/deep/.ant-modal{
  width:95vw !important; 
  top: 5vh;
}
/deep/.ant-modal-content,/deep/.ant-modal-header{
   background-color: rgba(237, 238, 242, 1);
}
// /deep/.ant-modal-content{
//   height:70vh;
// }
/deep/.ant-modal-body{
  padding:1vh 1vw;
}
/deep/.ant-modal-footer{
  text-align: center;
  display: none;
}
/deep/.ant-btn-primary{
   border-color: rgba(255, 102, 0, 1);
   background-color: rgba(255, 102, 0, 1);
}
/deep/.ant-modal-footer button + button{
  margin-left:2vw ;
}
/deep/.ant-modal-title{
  font-size: 1.8vh;
}
/deep/.ant-form-item-required::before{
  color:rgba(255, 102, 0, 1);
}
/deep/.ant-form-item{
  display: flex;
}
/deep/.ant-form-item-control {
  width:12vw;
}
/deep/.ant-form-item label {
  font-size:1.8vh;
}
/deep/.ant-table-thead > tr > th{
  background: rgba(237, 238, 242, 1);
  font-size: 1.8vh;
  padding:1.5vh 0.7vw;
  font-weight: 550;
   border-color: rgba(208, 208, 208, 1); 
} 
/deep/.ant-table-tbody > tr > td {
  font-size: 1.8vh;
  padding:1.5vh 0.7vw;
   border-color: rgba(208, 208, 208, 1);
}
.record{
  font-size: 1.8vh;
  margin-bottom:1vh;
}

/deep/.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
 background: rgba(237, 238, 242, 1);
}
/deep/table{
   border-color: rgba(208, 208, 208, 1) !important; 
}
.biao{
  font-size: 1.5vh;
  color:rgba(255, 0, 0, 1);
  font-style:italic;
  display: inline-block;
}

</style>
