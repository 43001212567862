<template>
 <a-modal
  title="口味"
  :visible="isShow"
  ok-text="确认" 
  cancel-text="取消"
  @ok="handleSubmit"
  @cancel="handleCancel" 
 >
  <div class="chooseModal">
     <a-form-model 
      :model="form"
      :rules="rules"
      ref="ruleForm"
      >      
      <a-form-model-item ref="taste"  prop="taste">
        <div class="taste">
          <a-radio-group
              v-model="form.taste"
          >
            <a-radio :value="item" v-for="(item ,index) in practice( data && data.tasteArr)" :key="index +'u'" >
              {{item}}
            </a-radio>
          </a-radio-group>
        </div>
      </a-form-model-item>
     </a-form-model >  
</div>
 </a-modal> 
</template>
<script>
export default {
  props:['data','isShow'],
  components: {
  },
  data() {
    return {
       form:{
          taste:''
        },
        rules:{
          taste:[{ required: true, message: '请选择口味', trigger: 'change' }]
        },   
    };
  },
   watch:{
    data:{
      handler(){
        this.form.taste=this.data && this.data.taste;
      },
      deep: true
    }
    },
  methods: {
    practice(datas){
      if(datas){
        return  datas.split(",")
      }
    },
    handleSubmit(){   //点击确定按钮 
      const _this=this;
       _this.$refs.ruleForm.validate((valid ) => {
        if (valid) {        
          const taste=this.form.taste && this.form.taste.toString();
           //console.log(JSON.stringify(values));
          this.$emit('chooseData',taste);//向父组件传递选取的数据
          this.$emit('cancel',false)//关闭按钮  
        }
      })
    },
    handleCancel(){ //点击取消按钮
    this.$emit('cancel',false)
    }
  }, 
};
</script>
<style lang="less" scoped>
/deep/.ant-modal{
  width:35vw !important;  
}
/deep/.ant-modal-content,/deep/.ant-modal-header{
   background-color: rgba(237, 238, 242, 1);
}
/deep/.ant-modal-body{
  padding:1vh 1vw;
}
/deep/.ant-radio-wrapper{
  font-size: 1.6vh;
  background-color: white;
  width:8vw;
  height: 3.5vh;
  padding-left: 0.5vw;
  line-height: 3.5vh;
  border-radius: 4px;
  border:1px solid rgba(208, 208, 208, 1);
  margin-bottom:1vh;
}
/deep/.ant-radio{
  line-height: 3.5vh;
}
/deep/span.ant-radio + * {
  padding-left:0.1vw;
  padding-top:0.2vh;
}
/deep/.ant-radio-wrapper-checked{
  background-color: rgba(255, 102, 0, 1);
  color:white;
}
/deep/.ant-radio-inner::after{
   background-color: rgba(255, 102, 0, 1);
}
/deep/.ant-radio-checked .ant-radio-inner{
  border-color: white;
}
/deep/.ant-modal-footer{
  text-align: center;
}
/deep/.ant-btn-primary{
   border-color: rgba(255, 102, 0, 1);
   background-color: rgba(255, 102, 0, 1);
   font-size:1.8vh;
  line-height: 3.5vh;
}
/deep/.ant-btn {
  width: 8vw;
  height: 4.6vh;
  font-size: 1.8vh;
  margin-right: 2vw;
  margin-left: 1vw;
}
/deep/.ant-modal-footer button + button {
  width: 8vw;
  height: 4.6vh;
  font-size: 1.8vh;
  border-radius: 0.5vh;
  color: white;
  margin-left: 5vh;
  background: rgb(238, 121, 12);
  border-color: rgb(238, 121, 12);
}
/deep/.ant-modal-title{
  font-size: 1.8vh;
}
/deep/.ant-form-item-required::before{
  color:rgba(255, 102, 0, 1);
}
.chooseModal{
  .title{
    font-size: 1.8vh;
    line-height: 5vh;
  }
}
  
</style>
