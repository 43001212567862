<template>
 <a-modal
  :title="data && data.goods_name"
  :visible="isShow"
  ok-text="确认" 
  cancel-text="取消"
  @ok="handleSubmit"
  @cancel="handleCancel" 
  width="40%"
 >
  <div class="chooseModal" v-if="isShow">
    <a-form-model :rules="rules" :model="form" ref="ruleForm" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
      <a-form-model-item  label="零售价:" v-if="data.spec_type===20 || data.is_weighing == 2">
        <div v-if="data.is_weighing == 2" class="weighing_goods">
          <span>￥{{goods_price}}元/千克</span>
          <b>小计：￥{{Number(goods_price * form.weight).toFixed(2)}}</b>
        </div>
        <div v-else>￥{{goods_price}}元</div>
      </a-form-model-item>
      <a-form-model-item  label="重量:" prop="weight" v-if="data.is_weighing == 2">
        <a-input v-model="form.weight"  suffix="| 千克" :disabled="disabled"/>
      </a-form-model-item>
      <a-form-model-item  label="规格:" prop="spec_content" v-if="data.spec_type===20">
        <a-radio-group v-model="form.spec_content">
          <a-radio :value="item.spec_name" v-for="(item ,index) in spec_content(data.spec_content)" :key="index +'s'" @change="changeHandle(item)">
            {{item.spec_name}}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="做法:" prop="practice" v-if="data.is_practice===2">
        <a-radio-group v-model="form.practice">
          <a-radio :value="item" v-for="(item ,index) in practice(data.practice)" :key="index +'b'">
            {{item}}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="口味:" prop="taste" v-if="data.is_taste===2">
        <div class="taste">
          <a-radio-group v-model="form.taste">
            <a-radio :value="item" v-for="(item ,index) in practice(data.taste)" :key="index +'u'" >
              {{item}}
            </a-radio>
          </a-radio-group>
        </div>
      </a-form-model-item>
    <a-form-model-item label="忌口:" prop="avoidlist" v-if="data.is_avoid===2">
      <div class="avoid">
        <a-checkbox-group v-model="form.avoidlist">
          <a-checkbox v-for="(item ,index) in practice(data.avoid)" :key="index +'z'" :value="item">
            {{item}}
          </a-checkbox>
        </a-checkbox-group>
      </div>
    </a-form-model-item>
  </a-form-model>
  <div style="padding:2vh;" v-if="data.is_weighing == 2">
    <keyNum :parentNum="this.form.weight" @hidden_num="updateNum"/>
  </div>
  
</div>
 </a-modal>
</template>
<script>
import keyNum from "@/components/keycode/num";
export default {
  props:['data','isShow'],
  components: {keyNum},
  data() {
    return {
      form: {
        weight:'',
        practice:'',
        taste:'',
        avoidlist:[],
        goods_price:'',
        avoid:''
      },//创建表单当前元素   
      goods_price:'' ,
      rules:{
        weight:[{ required: true, message: '', trigger: 'change' },
        { pattern : /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/, message: '请输入大于零的数'}
        ],
        spec_content:[{required: true,message: '请选择规格'}],
        practice:[{required: true,message: '请选择做法'}],
        taste:[{required: true,message: '请选择口味'}]
      },
      times:"",
      fina:"",
      disabled:false
    };
  },
  watch:{
    isShow(val){
      if(val){
        this.goods_price = this.data.goods_price;
        const instllObject= JSON.parse(localStorage.getItem('install'))?JSON.parse(localStorage.getItem('install')):{use:2,factory:undefined,type:undefined,com:undefined,potter:undefined,}
        if(instllObject.use==1 && this.data.is_weighing == 2){
            const com=instllObject.com;
            const potter=parseFloat(instllObject.potter);
            const a=bound.getWeight(com, potter).replace(/[\x00-\x1f]+/g, '');
            const b=(parseFloat(String(a).replace(/[^0-9,.]/ig, ""))).toFixed(2);
            if(b && b !=='NaN' && b !== 'undefined' && b !== 'null'   ){
              if(a.indexOf("-")!=-1){
                  this.form.weight=-Number(b)
              }else{
                  this.form.weight=b;
              }
            }else{
                this.form.weight=0.00
            }
            this.times=setInterval(() => {
                    setTimeout( 
                    this.weight(com,potter)
                    , 0)           
            },200);
        }
      }
    },
    form:{
      handler(){
        const instllObject= JSON.parse(localStorage.getItem('install'))?JSON.parse(localStorage.getItem('install')):{use:2,factory:undefined,type:undefined,com:undefined,potter:undefined,}
        if(instllObject.use==2){
          this.$set(this.form , 'weight' , this.getTofixed(this.form.weight))
        }
      },
      deep:true,
    },
    'form.weight':{
      handler(newValue,oldValue){
       this.fina=newValue;
      },
      deep:true,
    },
  },
  methods: {
    weight(com,potter){
      const a=bound.getWeight(com, potter).replace(/[\x00-\x1f]+/g, '');
      const b=(parseFloat(String(a).replace(/[^0-9,.]/ig, ""))).toFixed(2);
      if(b && b !=='NaN' && b !== 'undefined' && b !== 'null'   ){
        if(a.indexOf("-")!=-1){
            this.form.weight=-Number(b)
            this.disabled=true;
        }else{
            this.form.weight=b;
            this.disabled=true;
        }
      }else{
          this.form.weight=0.00
          this.disabled=true;
      }
    },
    practice(datas){
      if(datas){
        return  datas.split(",")
      }
    },
    spec_content(val){
      if(val){
        return JSON.parse(val)
      }
    },
    checkBoxClick(){
    },
    handleSubmit(){   //点击确定按钮 
      const _this=this;
      this.$refs.ruleForm.validate(valid => {
        if (valid) { 
          if(this.form.weight !="0"){
             _this.form.goods_price = this.goods_price
            _this.form.avoid = this.form.avoidlist.join(',')
            this.form.spec_type = this.data.spec_type
            _this.$emit('chooseData',this.form);//向父组件传递选取的数据
            _this.$refs.ruleForm.resetFields(); //清除书写框   
            _this.$emit('cancel',false)//关闭按钮 
            clearInterval(this.times);
          }else{
            this.$message.error('请进行商品称重')
          }
         
          
        }
      })
    },
    handleCancel(){ //点击取消按钮

      this.$refs.ruleForm.resetFields(); //清除书写框
      this.$emit('cancel',false)
      clearInterval(this.times);
    },
    changeHandle(val){
      console.log(val);
      this.$set(this , 'goods_price' , Number(val.spec_price).toFixed(2))
    },
    updateNum(num){
      this.form.weight = num;
    },
  }, 
};
</script>

<style lang="less" scoped>
// /deep/.ant-modal{
//   width:35vw !important;  
// }
.weighing_goods{
  display: flex;
  justify-content: space-between;
  b{
    color: rgba(255, 102, 0, 1);
  }
}
/deep/.ant-modal-content,/deep/.ant-modal-header{
   background-color: rgba(237, 238, 242, 1);
}
/deep/.ant-modal-body{
  padding:1vh 1vw;
}
/deep/.ant-radio-wrapper{
  font-size: 1.6vh;
  background-color: white;
  width:8vw;
  height: 3.5vh;
  padding-left: 0.5vw;
  line-height: 3.5vh;
  border-radius: 4px;
  border:1px solid rgba(208, 208, 208, 1);
  margin-bottom:1vh;

}
/deep/.ant-radio{
  line-height: 3.5vh;
}
/deep/span.ant-radio + * {
  padding-left:0.1vw;
  padding-top:0.2vh;
}
/deep/.ant-radio-wrapper-checked{
  background-color: rgba(255, 102, 0, 1);
  color:white;
}
/deep/.ant-radio-inner::after{
   background-color: rgba(255, 102, 0, 1);
}
/deep/.ant-radio-checked .ant-radio-inner{
  border-color: white;
}
/deep/.ant-checkbox-wrapper{
  font-size: 1.6vh;
  background-color: white;
  width:8vw;
  height: 3.5vh;
  padding-left: 0.5vw;
  line-height: 3.5vh;
  border-radius: 4px;
  border:1px solid rgba(208, 208, 208, 1);
}
/deep/.ant-checkbox + span{
  padding-left:0.1vw;
  padding-top:0.2vh;
}
/deep/.ant-checkbox-wrapper-checked{
  background-color: rgba(255, 102, 0, 1);
  color:white;
}
/deep/.ant-input-suffix{
  color: rgba(255, 102, 0, 1);
}
/deep/.ant-checkbox-checked .ant-checkbox-inner{
   border-color: white;
   background-color: rgba(255, 102, 0, 1);
}
/deep/.ant-modal-footer{
  text-align: center;
}
/deep/.ant-btn-primary{
   border-color: rgba(255, 102, 0, 1);
   background-color: rgba(255, 102, 0, 1);
}
/deep/.ant-btn {
  width: 8vw;
  height: 4.6vh;
  font-size: 1.8vh;
  margin-right: 2vw;
  margin-left: 1vw;
}
/deep/.ant-modal-footer button + button {
  width: 8vw;
  height: 4.6vh;
  font-size: 1.8vh;
  border-radius: 0.5vh;
  color: white;
  margin-left: 5vh;
  background: rgb(238, 121, 12);
  border-color: rgb(238, 121, 12);
}
/deep/.ant-modal-footer button + button{
  margin-left:2vw ;
}
/deep/.ant-modal-title{
  font-size: 1.8vh;
}
/deep/.ant-form-item-required::before{
  color:rgba(255, 102, 0, 1);
}
/deep/.ant-form-item{
  margin-bottom: 0;
}

.chooseModal{
  .title{
    font-size: 1.8vh;
    line-height: 5vh;
  }

}
  
</style>
