<template>
  <div>
    <a-modal
       :visible="show"
      :title="type == 'weixin' ? '个人微信' : '个人支付宝'"
      cancel-text="取消"
      ok-text="确定"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div>
       <div class="record">
          <div class="zero">待收:<span>&nbsp;&nbsp;&nbsp;&nbsp;{{sheng}}元</span></div>
          <!-- <div class="dai zero">找零:<span>&nbsp;&nbsp;&nbsp;&nbsp;{{zero}}元</span></div> -->
        </div>  
      <a-form-model 
          :model="form" 
          :rules="rules"
          ref="ruleForm"
          :label-col="{ span: 4}" 
          :wrapper-col="{ span: 10}"
        >
          <a-form-model-item ref="price" label="收到" prop="price">
            <a-input
              class="input"
              v-model="form.price"
              placeholder="请输入金额"
              @keyup.enter.native="handleOk"
            ></a-input>
          </a-form-model-item>
          <div class="ji">
             <keyNum :parentNum="this.form.price" @hidden_num="updateNum"/> 
          </div>         
      </a-form-model> 
      </div>
    </a-modal>
  </div>
</template>
<script>
import keyNum from "@/components/keycode/num.vue";
import {orderpay,orderpaystatus} from '@/request/business.js';
export default {
  props:['show','sheng','type'],
  components:{keyNum},
  data() {
    return {
       form: {
          price:"",
        },
        rules: {
          price: [
            {required: true,  trigger: "change" ,message: "请输大于0的数字",},
          ] 
        },
        // zero:"0.00",//找零金额
        pay:'',//付款金额
   }
  },
  watch:{
    show(val){
      if(val){
        this.form.price = this.sheng
      }
    },
    form:{
      handler(){
        if(this.form.price * 1 > this.sheng * 1){
            
            this.$message.warning('支付金额不能大于待收金额')
            this.form.price = this.form.price.slice(0,this.form.price.length - 1)
        }else{
          this.$set(this.form , 'price' , this.getTofixed(this.form.price))
            this.pay = this.form.price
        }
      },
      deep:true,
    }, 
  },
  updated(){
  },
  methods: {
    formatDecimal(num, decimal){
       num = num.toString()
                let index = num.indexOf('.')
                if (index !== -1) {
                    num = num.substring(0, decimal + index + 1)
                } else {
                    num = num.substring(0)
                }
                return parseFloat(num).toFixed(decimal)

    },
     updateNum(num){
      this.form.price = num;
    }, 
    handleCancel(){
       this.form.price="";
      this.$emit('cancel',false)//关闭按钮
    }, 
    orderRequest(){ 
      const _this=this; 
      const foundingLoId=this.$route.query.foundingLoId && this.$route.query.foundingLoId;
      const message={
        foundingLoId:foundingLoId,
        pay_type: this.type == 'weixin' ? 'personal.wechat' : 'personal.alipay',  //付款方式
        auth_no:'',
        current_price:this.pay,  //付款金额
      }  
       orderpay(message)
            .then((res) => {
              if(res.data.status===200){
                const paylog_id=res.data.data.paylog_id  && res.data.data.paylog_id;
                _this.orderStatusRequest(paylog_id);//请求获取支付状态     
              }                 
            })
            .catch(result => {
              return false;
          })
    },
     orderStatusRequest(id){   //余额结算获取支付状态
      orderpaystatus({paylog_id:id})
        .then((res) => {
          if(res.data.status===200){
            const listItem=res.data.data.status  && res.data.data.status;
            this.form.price="";
            this.$emit('cancel',false)//关闭按钮
            this.$emit('chooseData',this.pay,listItem,);//向父组件传递选取的数据         
          }                   
          })
        .catch(result => {
            return false;
          })
    },
    handleOk() {
       const _this=this;
      _this.$refs.ruleForm.validate(valid => {
        if (valid) {  
          _this.orderRequest();        
        } else {
          return false;
        }
      });
     
    },
  },
};
</script>
<style lang="less" scoped>
.zero{
  font-size: 2.0vh;
  line-height:5vh ;
  margin-left:2vw;
  span{
    color:#ff6700;
    font-size: 2.0vh;
  }
}
.ji{
  padding:0 2vw;
}
/deep/.ant-modal-content{
  width: 35vw;
  background:rgba(237, 238, 242, 1);
}
/deep/.ant-form-item-label {
  margin-top: 2vh;
  font-size: 2.2vh;
  height:6vh;
  line-height: 6vh;
}
/deep/.ant-form-item{
  margin-bottom:3vh;
}
/deep/.ant-form-item-control {
  margin-top: 2vh;
}
.ant-input {
  width: 18vw;
  border-color: #999999;
  background: #edeef2;
  height:6vh;
  font-size: 2.0vh;
}
  /deep/.ant-modal-footer {
  text-align: center;
  margin-top: 3vh;
}
/deep/.ant-btn {
  width: 13vh;
  height: 6vh;
  font-size: 2.0vh;
}
/deep/.ant-modal-footer button + button {
  width: 7vw;
  height: 5vh;
  font-size: 1.8vh;
  border-radius: 0.5vh;
  color: white;
  margin-left: 5vh;
  background: rgb(238, 121, 12);
  border-color: rgb(238, 121, 12);
  width:13vh;
  height:6vh;
  font-size: 2.0vh;
}
/deep/.ant-modal-body{
  font-size: 1.8vh;
  padding: 0 1vw;
}
/deep/.ant-modal-title{
  font-size: 2.0vh;
  font-weight: bold;
}
/deep/.ant-form label{
  font-size: 2.0vh;
  color:rgba(0, 0, 0, 0.65);
}
/deep/.ant-modal-header{
   background:rgba(237, 238, 242, 1);
}
/deep/.anticon svg{
  font-size: 2.8vh;
  color:#999999;
  margin-top:1.5vh;
}
/deep/.ulJian{
  border: 1px solid #999999;
  border-radius: 4px;
  li{
    width:33.3%;
    height: 7.5vh;
    line-height: 7.5vh;
    font-size: 2.2vh;
  }
}
.record{
  display: flex;
  justify-content:space-between;
}
.dai{
  margin-right: 4vw;
}
.dai span{
  color:#2a82e4;  
}

</style>