<template>
    <a-modal title="送单备注" :visible="isShow" ok-text="确认" cancel-text="取消" @ok="handleSubmit" @cancel="handleCancel">
        <div class="historyRemark">
            <div class="left">历史备注：</div>
            <div class="right" v-if="remarkData && remarkData.length">
                <div v-for="(item,i) in remarkData" :key="i">{{i + 1}} 、 {{item}}</div>
            </div>
        </div>
        <div class="oftenRemark">
            <div @click="clickHandle(item)" v-for="(item,i) in list" :key="i">{{item}}</div>
        </div>
        <a-textarea placeholder="请输入送单备注" v-model="remark" :rows="4" />
    </a-modal>
</template>
<script>
import {queryRemarkList} from '@/request/business.js';
export default {
    data(){
        return{
            remark:'',
            list:[]
        }
    },
    props:{
        isShow:{
            default:false,
            type:Boolean
        },
        remarText:{
            default:'',
            type:String
        },
        remarkData:{
            default:[],
            type:Array
        }
    },
    watch:{
        isShow(val){
            if(val){
                this.remark = this.remarText
                queryRemarkList().then(res => {
                    console.log(res);
                    if(res.data.status == 200){
                        this.list = res.data.data.remark.split(',')
                    }
                })
            }
        }
    },
    methods:{
        handleSubmit(){
            if(this.remark){
                this.$emit('handleRemark',this.remark)
            }else{
                this.$message.warning('请输入备注')
            }
        },
        handleCancel(){
            this.$emit('handleCancel')
        },
        clickHandle(item){
            let str = this.remark ? this.remark + '，' + item : item
            this.$set(this , 'remark' , str)
        }
    }
}
</script>
<style lang="less" scoped>
.historyRemark{
    display: flex;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 2vh;
    .left{
        font-size: 2.2vh;
        padding: 1vh 0;
    }
    .right{
        font-size: 2vh;
        flex: 1;
        >div{
            border-top: 1px solid #e5e5e5;
            padding: 1vh 0;
            &:first-child{
                border: none;
            }
        }
    }
}
.oftenRemark{
    display: flex;
    padding: 1vh 0 2vh;
    flex-wrap: wrap;
    >div{
        width: 10vw;
        text-align: center;
        background: #ffffff;
        border-radius: 0.5vh;
        margin:0 1vw 1vh 0;
        cursor: pointer;
        line-height: 4vh;
    }
}
/deep/.ant-modal-content{
    background: #edeef2;
}
/deep/.ant-modal-header{
    background: #edeef2;
    border-color: #e5e5e5;
}
/deep/.ant-input{
    background: #edeef2;
}
/deep/.ant-btn {
  width: 8vw;
  height: 4.6vh !important;
  font-size: 1.8vh;
  margin-right: 2vw;
  margin-left: 1vw;
}
/deep/.ant-modal-footer button + button {
  width: 8vw;
  height: 4.6vh !important;
  font-size: 1.8vh;
  border-radius: 0.5vh;
  color: white;
  margin-left: 5vh;
} 
/deep/.ant-modal-footer{
    text-align:center;
}
</style>