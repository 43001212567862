import { render, staticRenderFns } from "./accountHintModal .vue?vue&type=template&id=3dd0fe20&scoped=true&"
import script from "./accountHintModal .vue?vue&type=script&lang=js&"
export * from "./accountHintModal .vue?vue&type=script&lang=js&"
import style0 from "./accountHintModal .vue?vue&type=style&index=0&id=3dd0fe20&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dd0fe20",
  null
  
)

export default component.exports