<template>
 <a-modal
  title="果粒橙"
  :visible="isShow"
  ok-text="确认" 
  cancel-text="取消"
  @ok="handleSubmit"
  @cancel="handleCancel" 
 >
  <div class="format">
    <div class="title">商品基本信息</div>
    <div class="price">零售价：￥5.00</div>
    <div class="choose">
     <div class="format">规格：</div>
     <!-- <div class="button" v-for=""></div> -->
    </div>
  </div>
 </a-modal>
</template>
<script>

export default {
  props:['data','isShow'],
  components: {},
  data() {
    return {
      format:[
        {value:'小瓶',id:1},
        {value:'大瓶',id:2},
      ]
    };
  },
 
  mounted(){ 
  },
  updated(){
    console.log(JSON.stringify(this.data))
  },
  methods: {
    handleSubmit(){   //点击确定按钮 
     
    },
    handleCancel(){ //点击取消按钮
     
    },
    
  }, 
};
</script>
<style lang="less" scoped>

</style>
