<template>
 <a-modal
  :title="'赠送: ['+goods_name+']  计价单位: [' +goods_unit +']'"
  :visible="isShow"
  ok-text="确认" 
  cancel-text="取消"
  @ok="handleSubmit"
  @cancel="handleCancel" 
 >
  <div class="chooseModal">
     <a-form-model 
      :model="form" 
      :rules="rules"
      ref="ruleForm"
     >
       <a-form-model-item ref="total_num" label="请输入" prop="total_num">
          <a-input
            class="input"
            v-model="form.total_num"
          ></a-input>
        </a-form-model-item>
      <keyNum :parentNum="this.form.total_num" @hidden_num="updateNum"/> 
     </a-form-model> 
</div>
 </a-modal>
</template>
<script>
import keyNum from "@/components/keycode/num.vue";
export default {
  props:['data','isShow'],
  components: {
    keyNum
  },
  data() {
     let validatorPhone=(rule,value,callback)=>{
       if(!value){
         callback(new Error('请输入数字'));
       }else if(parseInt(value)>parseInt(this.data.total_num)){
           callback(new Error('赠送数量不能超过已点数量'));
         
       }else if(parseInt(value) < parseInt(this.data.total_num) || parseInt(value) === parseInt(this.data.total_num) ){
         callback()
       }
     
      
        console.log(JSON.stringify(parseInt(value)))
        console.log(JSON.stringify(parseInt(this.data.total_num)))
    };
    return {
        form: {
          total_num:"1"
        },
        goods_name:'',
        goods_unit:'',
        rules: {
        total_num: [
          { validator:validatorPhone,required: true,  trigger: "blur" ,},
          { pattern:  /^\+?[1-9][0-9]*$/, message: "请输大于0的整数", trigger: "blur" },
        ],
      },  
    };
  },
   watch:{
    data:{
      handler(){
          this.goods_name=this.data ? this.data.goods_name :'';
          this.goods_unit=this.data?  this.data.goods_unit:'';
      },
      deep:true
    }
  },
   mounted(){ 
  },
  updated(){
     //console.log(JSON.stringify(this.data))
    //console.log(JSON.stringify(this.data.total_num))
  },
  methods: {
     updateNum(num){
      this.form.total_num = num;
    },
    handleSubmit(){   //点击确定按钮 
      const _this=this;
      _this.$refs.ruleForm.validate(valid => {
        if (valid) {
          _this.$emit('cancel',false)//关闭按钮
          _this.$emit('chooseData',_this.form.total_num);//向父组件传递选取的数据
        } else {
          return false;
        }
      });
    },
    handleCancel(){ //点击取消按钮
    this.$emit('cancel',false)
    // this.form.resetFields(); //清除书写框
    }
  }, 
};
</script>
<style lang="less" scoped>
/deep/.ant-modal{
  width:23vw !important;  
}
/deep/.ant-modal-content,/deep/.ant-modal-header{
   background-color: rgba(237, 238, 242, 1);
}
/deep/.ant-modal-body{
  padding:1vh 1vw;
}
/deep/.ant-modal-footer{
  text-align: center;
}
/deep/.ant-btn-primary{
   border-color: rgba(255, 102, 0, 1);
   background-color: rgba(255, 102, 0, 1);
}
/deep/.ant-modal-footer button + button{
  margin-left:2vw ;
}
/deep/.ant-modal-title{
  font-size: 1.8vh;
}
/deep/.ant-form-item-required::before{
  color:rgba(255, 102, 0, 1);
}
/deep/.ant-form-item{
  display: flex;
}
/deep/.ant-form-item-control {
  width:12vw;
}
/deep/.ant-form-item label {
  font-size:1.8vh;
}
.chooseModal{
  .title{
    font-size: 1.8vh;
    line-height: 5vh;
  }
}
  
</style>
